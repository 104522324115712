import React, { createContext, useContext, FC, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { hideForm } from '../redux/slices/benefitsSlice';
import { fetchPersonBenefits, fetchPrograms } from '../redux/thunks/fetchPrograms';
import { useAppDispatch } from '../redux/hooks';

type ChannelCtx = {
  channel: BroadcastChannel,
  sendMessage: (message: string) => void,
};

const channelContext = createContext({} as ChannelCtx);

const useProvideChannel = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const channel = new BroadcastChannel('benefit_channel');
  const sendMessage = (message: string) => {
    channel.postMessage(message);
  };
  useEffect(() => {
    channel.onmessage = (event) => {
      if (event.data === 'program_connected') {
        dispatch(fetchPersonBenefits());
        dispatch(fetchPrograms());
        setTimeout(() => {
          dispatch(hideForm());
          navigate('/');
        }, 1000);
      }
    };

    return () => {
      channel.close();
    };
  }, [channel]);
  return {
    channel,
    sendMessage,
  };
};

type PCProps = {
  children:ReactNode
};

export const ProvideChannel: FC<PCProps> = ({ children }) => {
  const channel = useProvideChannel();
  return (
    <channelContext.Provider value={channel}>
      {children}
    </channelContext.Provider>
  );
};

export const useChannelContext = () => {
  return useContext(channelContext);
};
