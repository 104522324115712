import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const StyledList = styled.ul`
    list-style:none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
`;

type Props = {
  children: ReactNode;
};

const ProgramList:FC<Props> = ({ children }) => {
  return (
    <StyledList>
      {children}
    </StyledList>
  );
};

export default ProgramList;
