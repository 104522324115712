import Benefit from "../../../../../../interfaces/benefit.interface";
import City from "../../../../../../interfaces/city.interface";
import { BenefitOptions } from "../formComponents/BenefitsSelect";
import { CityOption } from "../formComponents/CitiesSelect";

export const getCityOption = (city:City):CityOption => {
    return {
        value: city,
        label: city ? city.name : ""
    }
}

export const getBenefitOption = (benefit:Benefit):BenefitOptions => {
    return {
        value: benefit,
        label: benefit ? benefit.title : "",
    }
}