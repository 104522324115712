/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

// COre
import { Radio, RadioGroup } from '@skbkontur/react-ui';
import { add } from 'date-fns';
import { eachMonthOfInterval } from 'date-fns/esm';
import format from 'date-fns/format';
import isSameYear from 'date-fns/isSameYear';
import { ru } from 'date-fns/locale';
import React, { FC, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// Functions
import calculatePersonProbation from '../../functions/calculateUserExperience';
import declOfNum from '../../functions/declOfNum';
import sameYearDetect from '../../functions/sameYearDetect';

// Types
import Benefit from '../../interfaces/benefit.interface';
import OrderDTO from '../../interfaces/orderDTO.interface';
import Program from '../../interfaces/program.interface';

// Store
import { currentBenefitSelector, currentOrderSelector, editFormSelector, userBenefitSelector } from '../../redux/selectors/benefits.selector';
import { personSelector } from '../../redux/selectors/person.selector';
import { addCreditPlanToOrder, addProgramDataToOrder, hideForm } from '../../redux/slices/benefitsSlice';
import { fetchCities } from '../../redux/thunks/citiesThunk';

// Components
import AvailablePrograms from '../AvailablePrograms';
import AdditionalProgramme from './AdditionalPrograms';
import ChildBenefit from './ChildBenefit';
import ProgramsWithOffset from './ProgramsWithOffset';

// Styles
import { RadioGroupWrapper, RadioLabel } from '../AvailablePrograms/AvailablePrograms.styled';
import {
  ContentDates,
  CreditPlan,
  Divider,
  ProgramPrice,
  RadioLabelSub,
  SideAccordionContent,
  SideAccordionWrapper,
  SidePageCity,
  SidePageCityDescription,
  SidePageCityLink,
  SignInput,
} from './SidePageAccordion.styled';
import { useAppDispatch } from '../../redux/hooks';

type PropTypes = {
  benefit: Benefit;
};

type Condition = {
  org: string[];
  offset: number;
  condition: number;
};

type DatePrice = {
  date?: Date,
  price: number,
  text: string,
  number: number,
};

const SidePageAccordion: FC<PropTypes> = ({ benefit }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentOrder = useSelector(currentOrderSelector);
  const currentBenefit = useSelector(currentBenefitSelector);
  const userBenefits = useSelector(userBenefitSelector);
  const editMode = useSelector(editFormSelector);
  const person = useSelector(personSelector);
  const probation = calculatePersonProbation(person, editMode ? new Date(benefit.bDate) : benefit.connected ? new Date() : new Date(benefit.bDate));
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const [isFree, setIsFree] = useState(false);
  const [dates, setDates] = useState<DatePrice[]>([] as DatePrice[]);
  const [currentProgram, setCurrentProgram] = useState<Program>({} as Program);
  const [creditPlan, setCreditPlan] = useState<DatePrice>({} as DatePrice);

  const getProgramOffset = (condition: Condition) => {
    if (probation >= condition.condition) {
      return condition.offset;
    }
    return 0;
  };

  const changeCityHandler = () => {
    navigate('/');
    dispatch(hideForm());
    dispatch(fetchCities());
  };

  const mapDates = () => {
    const eDate = add(new Date(), { months: 3 });
    const months = eachMonthOfInterval({ start: add(new Date(), { months: 0 }), end: eDate });
    const mappedDate = months.map((month, idx) => {
      if (idx === 0) {
        return {
          text: 'Без рассрочки, вычесть сразу',
          price: currentProgram?.price?.price,
          number: 0,
        };
      }
      if (idx === 1) {
        return;
      }
      return {
        date: month,
        price: currentProgram?.price?.price / idx,
        text: `${idx} ${declOfNum(idx, ['месяц', 'месяца', 'месяцев'])}`,
        number: idx,
      };
    }).filter((item) => item);
    setDates(mappedDate);
    // setCreditPlan(mappedDate[0]);
    if (editMode) {
      const notPackageCreditPlan = userBenefits.find((userBenefit) => userBenefit.benefitData.benefit === currentBenefit._id).benefitData.creditPlan;
      setCreditPlan(mappedDate.find((date) => date.number === notPackageCreditPlan) || mappedDate[0]);
    }
  };

  const handleDelete = () => {
    setOrder({
      benefit: benefit._id,
      program: null,
      offset: null,
      creditPlan: null,
      signatureUrl: null,
    });

    dispatch(addProgramDataToOrder({
      benefit: benefit._id,
      program: null,
      offset: null,
      creditPlan: null,
      signatureUrl: null,
    }));
    setTimeout(() => {
      setCurrentProgram({} as Program);
    }, 400);
  };

  const handleInputValueChange = (e: string) => {
    const orderData: OrderDTO = {
      ...currentOrder,
      signatureUrl: e,
    };
    setOrder(orderData);
    dispatch(addProgramDataToOrder(orderData));
  };

  const handleValueChange = (e) => {
    const { value } = e.target;
    const selectedProgram = benefit.programs.find((program) => program._id === value);

    setCurrentProgram(selectedProgram);
    const orderData: OrderDTO = {
      ...order,
      program: value,
      offset: isFree ? getProgramOffset(selectedProgram.offsetCondition[0]) : 0,
      benefit: benefit._id,
      prefix: selectedProgram.prefix,
      creditPlan: isFree ? 0 : dates[0]?.number ? dates[0].number : null,
      signatureUrl: currentOrder.signatureUrl,
    };
    setOrder(orderData);
    dispatch(addProgramDataToOrder(orderData));
    return order;
  };

  const handleCreditPlanChange = (idx) => {
    setCreditPlan(dates[idx]);
    setOrder({
      ...order,
      offset: isFree ? getProgramOffset(currentProgram.offsetCondition[0]) : 0,
      creditPlan: dates[idx].number,
    });

    dispatch(addCreditPlanToOrder({
      ...order,
      children: [],
      benefit: benefit._id,
      offset: isFree ? getProgramOffset(currentProgram.offsetCondition[0]) : 0,
      creditPlan: dates[idx].number,
    }));
  };

  useEffect(() => {
    if (editMode) {
      setOrder({
        ...currentOrder,
      });
      setCurrentProgram(benefit.programs.find((program) => program._id === currentOrder.program));
    }

    benefit?.programs?.forEach((program) => {
      let freeProgram = program.offsetCondition.find((condition) => {
        if (condition) {
          return (condition.org.length && condition.offset === 100 && condition.condition <= probation)
            || (!condition.org.length && condition.offset === 100 && condition.condition <= probation);
        }
        return false;
      });
      if (program.price.price === 0) {
        // eslint-disable-next-line prefer-destructuring
        freeProgram = program.offsetCondition[0];
      }
      if (freeProgram) {
        setIsFree(true);
      }
      if (benefit.connected) {
        const existFreeBenefit = userBenefits.find((uBenefit) => uBenefit.benefitData.benefit === benefit._id);
        if (existFreeBenefit.benefitData.offset === 100) {
          setIsFree(true);
        }
      }
      if (benefit.programs.length === 1) {
        setCurrentProgram(benefit.programs[0]);
        setOrder({
          ...order,
          benefit: benefit._id,
          program: benefit.programs[0]._id,
          prefix: benefit.programs[0].prefix,
          offset: freeProgram ? getProgramOffset(benefit.programs[0].offsetCondition[0]) : 0,
          creditPlan: freeProgram ? 0 : null,
          signatureUrl: currentOrder.signatureUrl,
        });
        dispatch(addProgramDataToOrder({
          ...order,
          benefit: benefit._id,
          program: benefit.programs[0]._id,
          prefix: benefit.programs[0].prefix,
          offset: freeProgram ? getProgramOffset(benefit.programs[0].offsetCondition[0]) : 0,
          creditPlan: freeProgram ? 0 : null,
          signatureUrl: currentOrder.signatureUrl,
        }));
      }
    });
  }, [benefit, editMode]);

  useEffect(() => {
    if (order.program && !currentOrder.program) {
      handleDelete();
    }
  }, [currentOrder]);
  useEffect(() => {
    if (order.program && !isFree) {
      mapDates();
    }
  }, [order.program]);

  return (
    <SideAccordionWrapper>
      <SideAccordionContent>
        <SidePageCity>
          <span>
            Город страхования: {person.city.name}
          </span>
          <br />
          <SidePageCityDescription>
            Чтобы подключить программу для другого города,
            измените&nbsp;город&nbsp;
            <SidePageCityLink
              onClick={() => changeCityHandler()}
            >
              на&nbsp;главной
            </SidePageCityLink>
          </SidePageCityDescription>
        </SidePageCity>
        <div>
          <span>
            Период страхования
          </span>
          <br />
          <ContentDates>
            {format(new Date(benefit.bDate), sameYearDetect(benefit.bDate), { locale: ru })}
            {` ${(isSameYear(new Date(benefit.bDate), new Date(benefit.eDate))) ? '' : new Date(benefit.bDate).getFullYear()} –  `}
            {format(new Date(benefit.eDate), sameYearDetect(benefit.eDate), { locale: ru })}
          </ContentDates>
        </div>
        {(benefit.meta?.canBeGift && benefit.type !== 'DMS' && isFree) ? (
          <ProgramsWithOffset />
        ) : (
          <>

            <AvailablePrograms
              benefit={benefit}
              order={order}
              isFree={isFree}
              handleValueChange={handleValueChange}
            />
            {(benefit.programs.length === 1) && !isFree && (
              <>
                <div>
                  <span>Стоимость</span>
                  {currentProgram?.price?.price > 0 && !isFree && (
                    <ProgramPrice><NumberFormat value={Math.round(currentProgram.price.price)} displayType="text" thousandSeparator={' '} /> ₽</ProgramPrice>
                  )}
                </div>
              </>
            )}
            {!isFree && (
              <CreditPlan onClick={(e) => e.stopPropagation()}>
                <RadioGroup
                  value={dates}
                >
                  <RadioGroupWrapper>
                    <span>Рассрочка</span>
                    {dates.map((datePrice, idx) => (
                      <>
                        <Radio
                          checked={order.creditPlan === datePrice.number}
                          onClick={(e) => handleCreditPlanChange(idx)}
                          value={datePrice.price}
                          key={datePrice.price}
                          name={datePrice.text}
                        >
                          <RadioLabel>
                            <span>{datePrice.text}</span>
                            {!isFree && (
                              <RadioLabelSub>
                                по
                                {' '}
                                <NumberFormat value={Math.round(datePrice.price)} displayType="text" thousandSeparator={' '} />
                                {' '}
                                {currentProgram.sign ? currentProgram.sign : '₽'}
                              </RadioLabelSub>
                            )}
                          </RadioLabel>
                        </Radio>
                      </>
                    ))}
                  </RadioGroupWrapper>
                </RadioGroup>
              </CreditPlan>
            )}
            {currentOrder.children?.length > 0 && (
              <ChildBenefit benefitId={currentOrder.children[0].benefit} />
            )}
            {currentOrder.additionalPrograms?.length > 0 && (
              <AdditionalProgramme />
            )}
            {!isFree && (
              <>
                <Divider />
                <div>
                  <span>Подпишите заявление об удержании стоимости <a href="https://wiki.skbkontur.ru/pages/viewpage.action?pageId=730182151">в&nbsp;Контур.Документах</a> и вставьте ссылку на него</span>
                  <SignInput placeholder="Ссылка на заявление" onValueChange={handleInputValueChange} value={currentOrder.signatureUrl} />
                </div>
              </>
            )}
          </>
        )}

      </SideAccordionContent>
    </SideAccordionWrapper>
  );
};

export default SidePageAccordion;
