import styled from 'styled-components';

export const PersonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  :hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);

  }
`;