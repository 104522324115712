import styled from 'styled-components';

export const InfoProgramWrapper = styled.div`
  box-sizing: border-box;
  min-height: 88px;
  padding: 8px 16px 16px;
  border-radius: var(--border-radius-default);
  cursor: pointer;
  background: #fff;
  position: relative;
  transition: all  0.4s ease;
  &:hover {
    box-shadow: var(--program-shadow);
    transition: all  0.4s ease;
  }
`;

export const InfoProgramHref = styled.a`
`;
