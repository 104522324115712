/* eslint-disable no-underscore-dangle */
// Core
import React, { useEffect, useState } from 'react';
import { isAfter, isBefore, isSameYear, subDays } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Components
import PersonCity from '../../../components/PersonCity';
import Program from '../../../components/Program';
import TemporaryPlug from '../../../components/TemporaryPlug';
import Title from '../../../components/Title';

// Types
import Benefit from '../../../interfaces/benefit.interface';

// Store
import { benefitsAfter, connectedBenefits, notConnectedBenefits } from '../../../redux/selectors/benefits.selector';
import { citySelector, personSelector } from '../../../redux/selectors/person.selector';
import { handleCityModal } from '../../../redux/slices/globalSlice';
import { fetchCities } from '../../../redux/thunks/citiesThunk';

// Styles
import calculatePersonProbation from '../../../functions/calculateUserExperience';
import { currentStubSelector } from '../../../redux/selectors/global.selector';
import { AvailableList, ChangeCityLink, PageWrapper } from './ProgramsAvailable.styled';

const ProgramsAvailable = () => {
  const isDev = process.env.REACT_APP_ENABLE_DEV_FEATURES === 'true';

  const allBenefits = useAppSelector(notConnectedBenefits);
  const person = useAppSelector(personSelector);
  const openDates = useAppSelector(currentStubSelector);
  const dispatch = useAppDispatch();
  const connectedUserBenefits = useAppSelector(connectedBenefits);
  const availableBenefits = useAppSelector(benefitsAfter);
  const userCity = useAppSelector(citySelector);
  const user = useAppSelector(personSelector);
  const connectedDMS = connectedUserBenefits
    .filter((item) => item.connected && item.type === 'DMS');
  const connectedTele = connectedUserBenefits
    .filter((item) => item.connected && item.type === 'tele');
  const connectedVzr = connectedUserBenefits
    .filter((item) => item.connected && item.type === 'vzr' && new Date(item.eDate).getFullYear() === 2023);
  const conntectedDant = connectedUserBenefits
    .filter((item) => item.connected && item.parents?.length);
  const [mainBenefit, setMainBenefit] = useState<Benefit>();
  const [isRegistrationEnded, setIsRegistrationEnded] = useState<boolean>();

  const [exclusionProgram, setExclusionProgram] = useState<string>('');
  const [connectedDMSYears, setConnectedDMSYears] = useState<number>();

  const freeForeignProgramm = availableBenefits.find((benefit) => {
    if (benefit.type === 'vzr') {
      const probation = calculatePersonProbation(person, new Date());
      return benefit.programs.some((program) => {
        const programsWithOffset = program.offsetCondition.filter((offset) => offset.offset === 100 && probation >= offset.condition);
        return programsWithOffset.length;
      });
    }
    return false;
  });

  useEffect(() => {
    if (allBenefits.length) {
      const foundedMainBenefit = allBenefits.find((benefit) => benefit.type === 'DMS');
      if (foundedMainBenefit) {
        setMainBenefit(foundedMainBenefit);
      }
      const checkTiming = [...allBenefits]
        .filter((benefit) => benefit.type !== 'base' && isSameYear(new Date(benefit.activationCondition.registrationUntil), new Date()))
        .every((benefit) => isAfter(new Date(), new Date(benefit?.activationCondition?.registrationUntil)));
      setIsRegistrationEnded(checkTiming);
    }
    availableBenefits
      .forEach((benefit) => {
        if (benefit.type === 'DMS' || benefit.type === 'vzr') {
          if (connectedDMS.length && benefit.type === 'DMS') { // Replace with SAME YEAR BENEFITS
            const dmsYears = connectedDMS
              .reduce((itemA, itemB) => {
                return new Date(itemB.eDate) > new Date(itemA) ? itemB.eDate : itemA;
              }, 0);
            if (dmsYears && new Date(benefit.eDate).getFullYear() > new Date(dmsYears).getFullYear()) {
              return true;
            }
            setExclusionProgram(benefit._id);
            return false;
          }
          setExclusionProgram('');
        }
      });
    const dmsYear = connectedDMS
      .reduce((itemA, itemB) => {
        return new Date(itemB.eDate) > new Date(itemA) ? itemB.eDate : itemA;
      }, 0);
    setConnectedDMSYears(new Date(dmsYear).getFullYear());
  }, [allBenefits]);
  const checkUserCity = () => {
    if (userCity) {
      return (userCity as string).toLowerCase().includes('удален')
        || (userCity as string).toLowerCase().includes('удалён')
        || (userCity as string).toLowerCase().includes('фактич')
        || (userCity as string).toLowerCase().includes('тамань')
        || (userCity as string).toLowerCase().includes('арголов')
        || (userCity as string).toLowerCase().includes('ерный яр');
    }
    return false;
  };

  const getChild = () => {
    if (!person.city) {
      return (
        <p>
          Мы не знаем ваше местонахождение. Чтобы посмотреть программы,
          {' '}
          <ChangeCityLink
            tabIndex={0}
            onKeyPress={null}
            role="button"
            onClick={() => {
              dispatch(fetchCities());
              dispatch(handleCityModal());
            }}
          >
            выберите город
          </ChangeCityLink>
        </p>
      );
    }
    if (user.partTimePerson) {
      return (
        <>
          <TemporaryPlug partTimePerson />
        </>
      );
    }
    if (checkUserCity()) {
      return (
        <>
          <TemporaryPlug notFound />
        </>
      );
    }
    if (availableBenefits?.length) {
      return (
        <>
          {availableBenefits
            && availableBenefits
              .filter((benefit) => {
                if (benefit.type === 'DMS' || benefit.type === 'vzr') {
                  if (connectedDMS.length && benefit.type === 'DMS') { // Replace with SAME YEAR BENEFITS
                    if (new Date(benefit.eDate).getFullYear() > connectedDMSYears) {
                      return true;
                    }
                    return false;
                  }
                  if (benefit.type === 'vzr' && !freeForeignProgramm) {
                    return false;
                  }
                  if (connectedDMS.length && benefit.type === 'vzr') {
                    return false;
                  }
                  return true;
                }
                return false;
              })
              .map((benefit) => (
                <Program key={benefit._id} benefit={benefit} />
              ))}

          <AvailableList>
            {availableBenefits && availableBenefits
              .filter((benefit) => benefit.type !== 'DMS' && benefit.type !== 'base' && benefit.type !== 'vzr')
              .filter((benefit) => {
                if (benefit.type === 'Optional' && connectedDMS.length && benefit.parents?.length && (connectedDMSYears >= new Date(benefit.eDate).getFullYear())) {
                  if (exclusionProgram && !benefit.parents.includes(exclusionProgram)) {
                    return true;
                  }
                  return false;
                }
                if (benefit.type === 'Optional' && conntectedDant && benefit.parents?.length) {
                  const connectedDantYears = conntectedDant
                    .reduce((itemA, itemB) => {
                      return new Date(itemB.eDate) > new Date(itemA) ? itemB.eDate : itemA;
                    }, 0);
                  if (new Date(benefit.eDate).getFullYear() > new Date(connectedDantYears).getFullYear()) {
                    return true;
                  }
                  return false;
                }
                if (benefit.type === 'tele' && connectedTele.length) {
                  const connectedTeleYears = connectedTele
                    .reduce((itemA, itemB) => {
                      return new Date(itemB.eDate) > new Date(itemA) ? itemB.eDate : itemA;
                    }, 0);
                  if (connectedTeleYears && new Date(benefit.eDate).getFullYear() > new Date(connectedTeleYears).getFullYear()) {
                    return true;
                  }
                  return false;
                }
                return true;
              })
              .map((benefit) => (
                <Program key={benefit._id} benefit={benefit} />
              ))}
          </AvailableList>
        </>
      );
    }
    return (
      <> </>
    );
  };

  return (
    <PageWrapper>
      <Title type="h2">
        Можно подключить
        {' '}
        {person.city && <PersonCity />}
      </Title>
      {getChild()}
    </PageWrapper>

  );
  return (
    <> </>
  );
};

export default ProgramsAvailable;
