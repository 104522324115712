import React, { CSSProperties, FC, ReactNode } from 'react';

type Props = {
  sx?: CSSProperties,
  children?: ReactNode,
  className?: string,
};

const Box:FC<Props> = ({ sx, children, className }) => {
  return (
    <div className={className} style={sx}>
      {children}
    </div>
  );
};

export default Box;
