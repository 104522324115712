import React, { FC, ReactNode } from 'react';
import { DevLayoutHeaderWrapper, DevLayoutWrapper } from './Devlayout.styled';
import Header from '../../components/Header';

type DLProps = {
  children:ReactNode
};

const DevLayout: FC<DLProps> = ({ children }) => {
  const isDev = process.env.REACT_APP_ENABLE_DEV_FEATURES === 'true';

  if (isDev) {
    return (
      <DevLayoutWrapper>
        {children}
      </DevLayoutWrapper>
    );
  }
  return <></>;
};

export default DevLayout;
