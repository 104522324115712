import styled from 'styled-components';

export const ErrorPageWrapper = styled.div`
  padding: 135px 0 0 309px;
  color: var(--black);
`;

export const PageTitle = styled.p`
  font: 500 24px/29px LabGrotesqueK, sans-serif;
  margin: 0 0 48px 0;
`;

export const ErrorText = styled.p`
  position: relative;
  font: 700 56px/64px LabGrotesqueK, sans-serif;
  margin: 0 0 48px 0;
`;

export const ErrorCode = styled.sup`
  position: absolute;
  font: 400 18px/24px LabGrotesqueK, sans-serif;
  color: #a0a0a0;
  margin-left: 7px;
`;

export const PageContent = styled.p`
  font: 400 18px/25px LabGrotesqueK, sans-serif;
  margin: 24px 0 16px 0;
`;

export const LinkDecorator = styled.span`
  & > a {
    font-size: 18px;
    text-decoration: none;
  }
`;
