import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
  display:flex;
  gap:10px;
`;

const NavList = styled.ul`
  display: flex;
  list-style:none;
  padding:0;
  margin:0;

  a {
    text-decoration: none;
    &:hover, &:visited, &:active {
      color: inherit;
    }
  }

  li {
    margin: 0;
    font: 500 18px/22px LabGrotesqueK, sans-serif;
    color: var(--black);
    cursor: pointer;
    padding-bottom: 8px;
    text-decoration: none;
    &:hover, &:visited, &:active {
      color: inherit;
    }
  }

  .current {
    li {
      border-bottom: 2px solid black;
    }
  }
`;

const AdminNav:FC = () => {
  return (
    <Nav>
      <NavList>
        <NavLink to="/admin/panel/employees" className={({ isActive }) => isActive && 'current'}>
          <li>Сотрудники</li>
        </NavLink>
      </NavList>
    </Nav>
  );
};

export default AdminNav;
