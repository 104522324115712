import { ThemeFactory } from '@skbkontur/react-ui';
import { THEME_2022 } from '@skbkontur/react-ui/lib/theming/themes/Theme2022';

export const myFlatTheme = ThemeFactory.create({ 
  btnBorderRadiusMedium: '8px',
  btnBorderRadiusSmall: '8px',
  btnBorderRadiusLarge: '8px',
  btnPrimaryActiveBg: '#222222', 
  btnPrimaryBg: '#222222', 
  btnPrimaryHoverBg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #222222;',
  btnDangerActiveBg: '#D9472B',
  btnDangerHoverBg: '#D9472B',
  btnDangerBg: '#D9472B',
  btnDangerBorder: '1px solid rgba(0, 0, 0, 0.16)',
  btnDangerHoverBorderColor: '1px solid rgba(0, 0, 0, 0.16)',
  btnDangerTextColor: '#ffffff',
  btnDangerDisabledBg: '#F6F6F6',
  tabColorFocus: '#222222',
  tabColorHover: '#222222',
  radioActiveBg: '#ffffff',
  radioBorderColorFocus: 'transparent',
  radioCheckedBorderColor: '1px solid rgba(0,0,0,0.15)',
  radioCheckedBgColor: '#ffffff',
  radioCheckedBulletColor: '#222222',
  radioCheckedHoverBgColor: 'transparent',
  radioBulletSize: '10px',
  radioSize: '18px',
  radioLabelGap: '12px',
  radioFontSize: '16px',
}, THEME_2022);