import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import { Button, Checkbox, Loader, SidePage } from '@skbkontur/react-ui';
import { isEqual } from 'lodash';
import Benefit from '../../../../../../interfaces/benefit.interface';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { citiesSelector } from '../../../../../../redux/selectors/global.selector';
import { getConnectableEmployeeBenefits, getConnectedEmployeeBenefits, getEmployee, getEmployeeBenefits, getFormBenefits, getFormBenefitsLoading, getIsConnectingBenefit, getIsDisconnectingBenefit, getIsUpdateEmployeeBenefitSuccessful, getIsUpdatingEmployeeBenefit } from '../../../../../../redux/selectors/employee.selector';
import CitiesSelect, { CityOption } from '../formComponents/CitiesSelect';
import BenefitsSelect, { BenefitOptions } from '../formComponents/BenefitsSelect';
import Box from '../../../../../../components/Box/Box';
import { FormRow } from '../formComponents/styled';
import ProgramDate from '../../../../../../components/ProgramList/ProgramDate/ProgramDate';
import ProgramTypeRadioGroup from '../formComponents/ProgramRadioGroup';
import PriceRadioGroup, { freePrice } from '../formComponents/PriceRadioGroup';
import Program from '../../../../../../interfaces/program.interface';
import City from '../../../../../../interfaces/city.interface';
import Price from '../../../../../../interfaces/price.interface';
import { getSubBenefits } from '../utils/getSubBenefits';
import { getBenefitOption, getCityOption } from '../utils/getOption';
import { connectBenefitToEmployee, disconnectBenefitFromEmployee, fetchAllBenefits, updateBenefitToEmployee } from '../../../../../../redux/thunks/employeeThunk';

type Props = {
  benefit:Benefit;
  onSidepageClose: () => void;
};

type FormSubBenefit = {
  subBenefit: Benefit;
  price?: Price;
};

type FormBenefitData = {
  city?: City;
  benefit?: Benefit;
  program?: Program;
  price?: Price;
  subProgram?: FormSubBenefit;
};

const FULL_DISCOUNT = 100;

const getDefaultFormData = (benefit:Benefit, city:City, childrenBenefit: Benefit) => {
  const connectedProgramTitle = benefit.connectionInfo.program;
  const defaultProgram = benefit.programs.find((program) => program.title === connectedProgramTitle);
  const defaultPrice = benefit.connectionInfo.offset === FULL_DISCOUNT ? freePrice : defaultProgram.price;
  let connectedChildrenProgramTitle: string;
  let childrenDefaultProgram: Program;
  let childrenDefaultPrice: Price;
  if (childrenBenefit) {
    connectedChildrenProgramTitle = childrenBenefit.connectionInfo.program;
    childrenDefaultProgram = childrenBenefit.programs.find((program) => program.title === connectedChildrenProgramTitle);
    childrenDefaultPrice = childrenBenefit.connectionInfo.offset === FULL_DISCOUNT ? freePrice : childrenDefaultProgram.price;
    return { price: defaultPrice, city, benefit, program: defaultProgram, subProgram: { subBenefit: childrenBenefit, price: childrenDefaultPrice } };
  }
  return { price: defaultPrice, city, benefit, program: defaultProgram };
};

const EditBenefitForm:FC<Props> = ({ benefit, onSidepageClose }) => {
  const dispatch = useAppDispatch();
  const { cities } = useAppSelector(citiesSelector);
  const programCity = cities.find((city) => city.name === benefit.connectionInfo.city);
  const employee = useAppSelector(getEmployee);
  const childrenBenefit = useAppSelector(getConnectedEmployeeBenefits).find((bnft) => bnft.parents?.includes(benefit._id));
  const defaultFormData = getDefaultFormData(benefit, programCity, childrenBenefit);
  const [formData, setFormData] = useState<FormBenefitData>(defaultFormData);
  const isNotChanged = isEqual(formData, defaultFormData);
  const [changed, setChanged] = useState(false);
  const isUpdating = useAppSelector(getIsUpdatingEmployeeBenefit);
  const isDisconnecting = useAppSelector(getIsDisconnectingBenefit);

  useEffect(() => {
    setFormData({ ...defaultFormData });
    setChanged(false);
  }, [benefit]);

  const handleSubmit:FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const data = {
      personId: employee._id,
      benefitId: formData.benefit._id,
      programId: formData.program._id,
      city: formData.city._id,
      offset: formData.price.price > 0 ? 0 : 100,
      price: formData.program.price.price,
      prefix: formData.program.price.code,
      subProgram: null,
    };

    if (formData.subProgram) {
      data.subProgram = {
        personId: employee._id,
        benefitId: formData.subProgram.subBenefit._id,
        programId: formData.subProgram.subBenefit.programs[0]._id,
        city: formData.city._id,
        price: formData.subProgram.subBenefit.programs[0].price.price,
        prefix: formData.subProgram.subBenefit.programs[0].price.code,
        offset: formData.subProgram.price.price > 0 ? 0 : 100,
        delete: false,
      };
    } else if (childrenBenefit) {
      data.subProgram = {
        personId: employee._id,
        benefitId: defaultFormData.subProgram.subBenefit._id,
        programId: defaultFormData.subProgram.subBenefit.programs[0]._id,
        city: defaultFormData.city._id,
        price: defaultFormData.subProgram.subBenefit.programs[0].price.price,
        prefix: defaultFormData.subProgram.subBenefit.programs[0].price.code,
        offset: defaultFormData.subProgram.price.price > 0 ? 0 : 100,
        delete: true,
      };
    }
    dispatch(updateBenefitToEmployee({ updateBenefit: data, userId: employee._id }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <SidePage.Header>{benefit.title}</SidePage.Header>
      <SidePage.Body>
        <SidePage.Container>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
            <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>Город страхования</p>
              <CitiesSelect disabled cities={[]} value={getCityOption(formData.city)} onChange={({ value }) => setFormData({ ...formData, city: value, benefit: null })} />
            </FormRow>
            <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>Программа</p>
              <BenefitsSelect
                disabled
                benefits={[]}
                value={getBenefitOption(formData.benefit)}
                onChange={({ value }) => {
                  setChanged(true);
                  setFormData({ ...formData, benefit: value, program: value.programs[0], price: freePrice, subProgram: null });
                }}
              />

            </FormRow>
            {formData.benefit && (
              <>
                <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p>Период страхования</p>
                  <ProgramDate benefit={formData.benefit} />
                </FormRow>
                <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
                  <p>Тип программы</p>
                  <ProgramTypeRadioGroup
                    programs={formData.benefit.programs}
                    value={formData.program}
                    onChange={(value) => {
                      setChanged(true);
                      setFormData({ ...formData, program: value, price: freePrice });
                    }}
                  />
                </FormRow>
                {formData.program
                  && (
                  <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
                    <p>Стоимость</p>
                    <PriceRadioGroup
                      programs={[formData.program]}
                      value={formData.price}
                      onChange={(value) => {
                        setChanged(true);
                        setFormData({ ...formData, price: value });
                      }}
                    />
                  </FormRow>
                  )}
                  { childrenBenefit && (
                  <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
                    <p>Дополнительные программы</p>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                        <Checkbox
                          size="medium"
                          checked={Boolean(formData.subProgram)}
                          onValueChange={(checked) => {
                            if (checked) setFormData({ ...formData, subProgram: { subBenefit: childrenBenefit, price: freePrice } });
                            if (!checked) {
                              setFormData({ ...formData, subProgram: null });
                            }
                            setChanged(true);
                          }}
                        >
                          <Box sx={{ display: 'flex', gap: 12 }}>
                            <Box>{childrenBenefit.title}</Box>
                            <Box sx={{ color: '#757575' }}>{`с ${new Date(childrenBenefit.bDate).toLocaleDateString()}`}</Box>
                          </Box>
                        </Checkbox>
                        {formData.subProgram && (
                          <Box sx={{ marginLeft: 20 }}>
                            <PriceRadioGroup
                              programs={childrenBenefit.programs}
                              value={formData.subProgram.price}
                              onChange={(value) => {
                                setChanged(true);
                                setFormData({ ...formData, subProgram: { ...formData.subProgram, price: value } });
                              }}
                            />
                          </Box>
                        )}

                      </Box>
                    </Box>
                  </FormRow>
                  )}
              </>
            )}

          </Box>

        </SidePage.Container>
      </SidePage.Body>
      <SidePage.Footer panel>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: 8 }}>
            <Button borderless disabled={isUpdating || isNotChanged || !changed || isDisconnecting} type="submit" use="primary">Сохранить</Button>
            <Button disabled={isUpdating || isDisconnecting} onClick={onSidepageClose}>Отменить</Button>
          </Box>
          <Button disabled={isUpdating || isDisconnecting} use="danger" onClick={() => dispatch(disconnectBenefitFromEmployee({ benefitId: benefit._id, userId: employee._id }))}>
            Отключить
          </Button>
        </Box>
      </SidePage.Footer>
    </form>
  );
};

export default EditBenefitForm;
