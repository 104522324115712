import styled from 'styled-components';
import { TagWrapper } from '../InfoTag/InfoTag.styled';
import dmscard from '../../assets/svg/dms_card.svg';
import insurance_small from '../../assets/svg/insurance_small.svg';

export const SubAdds = styled.span<{ lines?: number }>`
  color: var(--secondary);
  margin: 0 0 0 8px; 
  max-width: ${(props) => (props.lines > 1 ? '190px' : 'fit-content')};
  white-space: ${(props) => (props.lines > 1 ? 'nowrap' : 'normal')};
`;

export const ProgramCardWrapper = styled.div<{ url?: string }>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  background: var(--white) url(${(props) => (props.url ? props.url : insurance_small)}) no-repeat right 4px bottom 4px;
  min-width: 280px;
  max-width: 280px;
  cursor: pointer;
  min-height: 148px;
  transition: all  0.4s ease;
  
  & > ${SubAdds} {
    font-size: 14px;
  }

  ${SubAdds} {
    overflow: hidden;
    margin: auto 0;
    text-overflow: ellipsis;
  }

  & > ${TagWrapper} {
    margin-top: 8px;
    margin-bottom: 0;
  }

  & > ${TagWrapper} + ${TagWrapper} {
    margin-top: 8px;
  }

  h3, h4 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &:hover {
    box-shadow: var(--program-shadow);
  }
`

export const ProgramCardWrapperWide = styled(ProgramCardWrapper) <{ url?: string }>`
  padding: 24px 32px;
  background: var(--white) url(${(props) => (props.url ? props.url : dmscard)}) no-repeat right 24px bottom 8px;
  max-width: 100%;
  h3 {
    margin: 0 0 8px;
  }
  ${SubAdds} {
    margin-left: 8px;
    max-width: fit-content;
  }
`;


export const ProgramCardSub = styled.div`
  display: flex; 
  align-items: center;
  margin: 14px 0 0;
  ${TagWrapper} {
    margin-left: 8px;
  }
`
