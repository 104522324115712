import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectBenefitById } from '../../../redux/selectors/benefits.selector';
import InfoTag from '../../InfoTag';
import { ChildBenefitContainer, ChildBenefitDescription, ChildBenefitRow, ChildBenefitTitle } from './ChildBenefit.styled';

interface ChildBenefitProps {
  benefitId: string;
}

const ChildBenefit: FC<ChildBenefitProps> = ({ benefitId }) => {
  const currentBenefit = useSelector(selectBenefitById(benefitId));
  if (currentBenefit) {
    return (
      <ChildBenefitContainer>
        <ChildBenefitRow>
          <ChildBenefitTitle>
            {currentBenefit.title}
          </ChildBenefitTitle>
          <InfoTag connected={currentBenefit.connected} benefitId={benefitId} child={currentBenefit.parent} programs={currentBenefit.programs} />
        </ChildBenefitRow>
        <ChildBenefitDescription>
          {currentBenefit.connected ? 'Подключено дополнительно' : 'Дополнительно '}
          {' '}
          вместе с основной программой ДМС
        </ChildBenefitDescription>
      </ChildBenefitContainer>
    );
  }

  return <></>;
};

export default ChildBenefit;
