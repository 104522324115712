import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, MigrationManifest, PersistState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import globalReducer from './slices/globalSlice';
import personReducer from './slices/personSlice';
import benefitsReducer from './slices/benefitsSlice';
import createMigrate from 'redux-persist/es/createMigrate';
import employeeReducer from './slices/employeeSlice';

const migrations: MigrationManifest = {
  '0': (state) => {
    return {
      _persist: {} as PersistState
    };
  },
}

const persistConfig = {
  key: 'root',
  version: 0,
  storage,
  migrate: createMigrate(migrations),
};

const reducers = combineReducers({
  global: globalReducer,
  person: personReducer,
  benefits: benefitsReducer,
  employee: employeeReducer
});

const _persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware)=>getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
});

const persistor = persistStore(store);

export { store, persistor };
export type RootPersist = ReturnType<typeof _persistedReducer>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
