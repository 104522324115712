import React, { FC } from 'react';
import { ProgramStubWrapper, ProgramStubParagraph, ProgramStubParagraphLong } from './ProgramStub.styled';

type Props = {
  title:string;
};

const ProgramStub:FC<Props> = ({ title }) => {
  return (
    <ProgramStubWrapper>
      <ProgramStubParagraph>
        {title}
      </ProgramStubParagraph>
      <ProgramStubParagraphLong>
        {/* С вами свяжется специалист
        <br />
        из отдела соц. программ
        <br />
        и подберет программы */}
      </ProgramStubParagraphLong>
    </ProgramStubWrapper>
  );
};

export default ProgramStub;
