
export const registerDate = (collect = 0) => {
  const registrationsObject: {
    [month: number]: {
      startDate: Date,
      endDAte: Date,
    }
  } = {
    0: {
      startDate: new Date(2024, 0, 19, 0, 0),
      endDAte: new Date(2024, 1, 1, 0, 0)
    },
    1: {
      startDate: new Date(2024, 1, 20, 0, 0),
      endDAte: new Date(2024, 2, 1, 0, 0)
    },
    2: {
      startDate: new Date(2024, 2, 20, 0, 0),
      endDAte: new Date(2024, 3, 1, 0, 0)
    },
    3: {
      startDate: new Date(2024, 3, 19, 0, 0),
      endDAte: new Date(2024, 4, 1, 0, 0)
    },
    4: {
      startDate: new Date(2024, 4, 20, 0, 0),
      endDAte: new Date(2024, 5, 1, 0, 0)
    },
    5: {
      startDate: new Date(2024, 5, 20, 0, 0),
      endDAte: new Date(2024, 6, 1, 0, 0)
    },
    6: {
      startDate: new Date(2024, 6, 19, 0, 0),
      endDAte: new Date(2024, 7, 1, 0, 0)
    },
    7: {
      startDate: new Date(2024, 7, 19, 0, 0),
      endDAte: new Date(2024, 8, 1, 0, 0)
    },
    8: {
      startDate: new Date(2024, 8, 20, 0, 0),
      endDAte: new Date(2024, 9, 1, 0, 0)
    },
    9: {
      startDate: new Date(2024, 9, 9, 0, 0),
      endDAte: new Date(2024, 10, 1, 0, 0)
    },
  }
  const currentMonth = new Date().getMonth();

  return registrationsObject[currentMonth + collect];
}