/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo } from 'react';
import Program from '../../../components/Program';
import ProgramStub from '../../../components/ProgramStub';
import Title from '../../../components/Title';
import { connectedBenefits, userBenefitSelector } from '../../../redux/selectors/benefits.selector';
import { AvailableList, PageWrapper } from '../ProgramsAvailable/ProgramsAvailable.styled';
import { setPersonMainProgramCity } from '../../../redux/slices/globalSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

const PluggedPrograms = () => {
  const dispatch = useAppDispatch();
  const allUserBenefits = useAppSelector(connectedBenefits);
  const userBenefits = useAppSelector(userBenefitSelector);

  const mainBenefit = useMemo(() => {
    return allUserBenefits.filter((benefit) => benefit.type === 'DMS' || benefit.type === 'vzr');
  }, [allUserBenefits]);

  useEffect(() => {
    if (mainBenefit) {
      const mainDMS = userBenefits.find((benefit) => benefit.benefitData.benefit === mainBenefit?.[0]?._id);
      if (mainDMS && mainDMS.benefitData?.benefitCity) {
        dispatch(setPersonMainProgramCity({ city: mainDMS.benefitData?.benefitCity }));
      }
    }
  }, []);

  return (
    <PageWrapper>
      <Title type="h2">
        Мои программы
      </Title>
      {!!allUserBenefits.length && (
        <>
          {allUserBenefits
            .filter((benefit) => (benefit.type === 'DMS' || benefit.type === 'vzr') && new Date(benefit.eDate).getFullYear() >= new Date().getFullYear())
            .map((item) => (
              <Program connected benefit={item} />
            ))}

          <AvailableList>
            {allUserBenefits && allUserBenefits
              .filter((benefit) => (benefit.type !== 'DMS' && benefit.type !== 'vzr') && new Date(benefit.eDate).getFullYear() >= new Date().getFullYear())
              .map((benefit) => (
                <Program connected={benefit.connected} key={benefit._id} benefit={benefit} />
              ))}
          </AvailableList>
        </>
      )}
      {!allUserBenefits.length && (
        <ProgramStub title="У вас пока нет программ" />
      )}
    </PageWrapper>

  );
};

export default PluggedPrograms;
