import React, { useEffect } from 'react';
import { formSelector } from '../../redux/selectors/benefits.selector';
import { fetchCompensationHistory, fetchPersonBenefits, fetchPrograms } from '../../redux/thunks/fetchPrograms';
import InfoPrograms from './InfoPrograms';
import PluggedPrograms from './PluggedPrograms';
import ProgramsAvailable from './ProgramsAvailable';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

const MainPage = () => {
  const dispatch = useAppDispatch();
  const isSidepageOpen = useAppSelector(formSelector);
  const isDev = process.env.REACT_APP_ENABLE_DEV_FEATURES === 'true';

  useEffect(() => {
    async function fetchData() {
      await dispatch(fetchPersonBenefits());
      await dispatch(fetchPrograms());
      if (isDev) {
        await dispatch(fetchCompensationHistory());
      }
    }

    fetchData();
  }, [isSidepageOpen]);
  return (
    <>
      <PluggedPrograms />
      <ProgramsAvailable />
      <InfoPrograms />
    </>
  );
};

export default MainPage;
