import React, { FC } from 'react';
import { Radio, RadioGroup } from '@skbkontur/react-ui';
import Benefit from '../../../../../../interfaces/benefit.interface';
import Box from '../../../../../../components/Box/Box';
import Program from '../../../../../../interfaces/program.interface';
import Price from '../../../../../../interfaces/price.interface';

export const freePrice:Price = {
  _id: 'free-price',
  price: 0,
  city: '',
  code: '',
};

type Props = {
  programs: Program[],
  value: Price,
  onChange: (value: Price) => void
};

const PriceRadioGroup:FC<Props> = ({ programs, value, onChange }) => {
  const paidPrograms = programs.filter((program) => program.price.price > 0);
  return (
    <Box>
      <RadioGroup onValueChange={onChange} width="100%" value={value}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Radio value={freePrice}>Бесплатно</Radio>
          {paidPrograms.map((program) => (
            <Radio value={program.price} key={`${program._id}-price`}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {paidPrograms.length > 1 ? <Box>{`Платно - ${program.title}`}</Box> : <Box>Платно</Box>}
                <Box sx={{ color: '#757575', fontSize: '14px' }}>
                  {program.price.price}
                  {' '}
                  ₽
                </Box>
              </Box>
            </Radio>
          ))}
        </Box>
      </RadioGroup>
    </Box>
  );
};

export default PriceRadioGroup;
