/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { loadAndRenderMenu } from '@skbkontur/services-menu';
import { HelpDot } from '@skbkontur/react-icons';
import { HeaderTitle, HeaderWrapper, KonturMenuItem, KonturMenuStub, HeaderIcon, HeaderControl, MenuWrapper } from './Header.styled';
import Avatar from '../Avatar';
import { citySelector } from '../../redux/selectors/person.selector';
import Location from '../../assets/svg/location.svg';
import '@skbkontur/services-menu/dist/basic.css';
import '@skbkontur/services-menu/dist/default.css';
import useOnClickOutside from '../../functions/useOnClickOutside';
import { useAppSelector } from '../../redux/hooks';

const Header = () => {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const cityName = useAppSelector(citySelector);
  const menu = useRef(null);
  const parent = useRef(null);

  const handleClickOutside = (e) => {
    if (parent?.current?.contains(e.target)) {
      return;
    }

    if (!isShowMenu) {
      return;
    }
    setIsShowMenu(false);
  };

  const handleOpen = (menuCondition: boolean) => {
    setIsShowMenu(!menuCondition);
  };

  const iconHandler = () => {
    window.open('https://cabinet.skbkontur.ru/help/faq#support_module=chat', '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    if (menu.current) {
      loadAndRenderMenu(menu.current, {
        navigateCallback() {
          setIsShowMenu(false);
        },
      });
    }
  }, [menu]);

  useEffect(() => {
    if (isShowMenu && document.documentElement.clientWidth > 1023) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, [isShowMenu]);

  useOnClickOutside(menu, handleClickOutside);

  return (
    <HeaderWrapper>
      <MenuWrapper active={isShowMenu}>
        <div ref={menu} />
      </MenuWrapper>
      <HeaderTitle href="/">
        Социальные программы
      </HeaderTitle>
      <HeaderControl>
        <div role="none" ref={parent} onClick={() => handleOpen(isShowMenu)}>
          <KonturMenuStub active={isShowMenu} />
        </div>
        <KonturMenuItem onClick={() => iconHandler()}>
          <HelpDot size={20} />
          Помощь
        </KonturMenuItem>
        <KonturMenuItem>
          <Avatar />
        </KonturMenuItem>
      </HeaderControl>
    </HeaderWrapper>
  );
};

export default Header;
