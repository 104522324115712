import React, { useEffect, useState } from 'react';
import { ArrowChevronLeft } from '@skbkontur/react-icons';
import { useParams } from 'react-router';
import {
  DefaultLink,
  Profession,
  TitleWrapper,
  UserPageWrapper,
} from './styled/styled';
import Title from '../../../components/Title';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchEmployee, fetchEmployeeBenefits } from '../../../redux/thunks/employeeThunk';
import { getEmployee, getIsBenefitConnectSuccessful, getIsBenefitDisconnectSuccessFul, getIsUpdateEmployeeBenefitSuccessful } from '../../../redux/selectors/employee.selector';
import { resetEmployeeBenefits, setEmployee, setIsConnectSuccess, setIsDisconnectSuccess, setIsUpdateSuccess } from '../../../redux/slices/employeeSlice';
import BenefitSidepage from './sidepage/benefitSidepage/BenefitSidepage';
import { setSelectedBenefit } from '../../../redux/slices/benefitsSlice';
import UserIcon from '../../../icons/UserIcon';
import { findUserJob } from '../../../redux/selectors/person.selector';
import { getJobName } from '../../SearchUser/UserSearchHistory/utils/getJobName';
import { citiesSelector } from '../../../redux/selectors/global.selector';
import { fetchCities } from '../../../redux/thunks/citiesThunk';
import Box from '../../../components/Box/Box';
import ConnectedBenefits from './programList/ConnectedBenefits';
import ConnectableBenefits from './programList/ConnectableBenefits';
import { getIsUserFired } from './utils/getIsFired';

const UserPage = () => {
  const { employeeId } = useParams();
  const [sidepageType, setSidepageType] = useState<'edit'|'connect'|null>(null);
  const dispatch = useAppDispatch();
  const employee = useAppSelector(getEmployee);
  const cities = useAppSelector(citiesSelector);
  const isBenefitDisconnectSuccess = useAppSelector(getIsBenefitDisconnectSuccessFul);
  const isBenefitUpdateSuccess = useAppSelector(getIsUpdateEmployeeBenefitSuccessful);
  const isBenefitConnectSuccess = useAppSelector(getIsBenefitConnectSuccessful);

  useEffect(() => {
    if (!cities?.cities) {
      dispatch(fetchCities());
    }
    dispatch(fetchEmployee(employeeId));
    dispatch(fetchEmployeeBenefits(employeeId));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setEmployee(null));
      dispatch(resetEmployeeBenefits());
    };
  }, []);

  useEffect(() => {
    if (isBenefitDisconnectSuccess) {
      setSidepageType(null);
      dispatch(fetchEmployeeBenefits(employeeId));
      dispatch(setIsDisconnectSuccess(false));
    }
  }, [isBenefitDisconnectSuccess]);

  useEffect(() => {
    if (isBenefitUpdateSuccess) {
      setSidepageType(null);
      dispatch(fetchEmployeeBenefits(employeeId));
      dispatch(setIsUpdateSuccess(false));
    }
  }, [isBenefitUpdateSuccess]);

  useEffect(() => {
    if (isBenefitConnectSuccess) {
      setSidepageType(null);
      dispatch(fetchEmployeeBenefits(employeeId));
      dispatch(setIsConnectSuccess(false));
    }
  }, [isBenefitConnectSuccess]);

  if (!employee) return <></>;

  const isFired = getIsUserFired(employee);
  return (
    <UserPageWrapper>
      <div>
        <TitleWrapper>
          <Title type="h2">
            <DefaultLink to="/">
              <ArrowChevronLeft />
            </DefaultLink>
            <Box>
              {employee.lastName}
              {' '}
              {employee.firstName}
              {' '}
              {employee.middleName}
            </Box>
          </Title>
        </TitleWrapper>
        <Profession>
          <p>{findUserJob(employee)?.currentJob ? getJobName(findUserJob(employee).currentJob.name) : 'Должность не найдена'}</p>
          <UserIcon user={employee} />
        </Profession>
      </div>
      <ConnectedBenefits onBenefitCLick={(benefit) => {
        setSidepageType('edit');
        dispatch(setSelectedBenefit(benefit));
      }}
      />
      {!isFired && (
        <ConnectableBenefits onBenefitCLick={(benefit) => {
          setSidepageType('connect');
          dispatch(setSelectedBenefit(benefit));
        }}
        />
      )}

      {sidepageType && (
        <BenefitSidepage
          type={sidepageType}
          onClose={() => {
            setSidepageType(null);
            dispatch(setSelectedBenefit(null));
          }}
        />
      )}
    </UserPageWrapper>
  );
};

export default UserPage;
