import React, { FC } from 'react';
import { Hint } from '@skbkontur/react-ui';
import parental_leave from '../assets/svg/parental_leave.svg';
import fired from '../assets/svg/fired.svg';
import Person from '../interfaces/person.interface';
import Box from '../components/Box/Box';
import { getIsUserFired } from '../pages/admin/UserPage/utils/getIsFired';

type Props = {
  user: Person;
};

const UserIcon:FC<Props> = ({ user }) => {
  const isFired = getIsUserFired(user);
  const isDecree = user.decree;

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {isDecree && (
      <Hint pos="right" text="Отпуск по уходу за ребенком">
        <img alt="Отпуск по уходу за ребенком" src={parental_leave} />
      </Hint>
      )}
      {isFired && (
      <Hint pos="right" text="Уволен">
        <img alt="Уволен" src={fired} />
      </Hint>
      )}
    </Box>
  );
};

export default UserIcon;
