import React, { FC } from 'react';
import { format, isSameYear } from 'date-fns';
import { ru } from 'date-fns/locale';
import Title from '../../Title';
import { CardBadge, CardBody, CardBodySm, CardData, CardDataSm, CardDate, CardHeader, CardIcon, CardIconSm, ProgramCard, ProgramCardSm } from './styled';
import Benefit from '../../../interfaces/benefit.interface';
import dmscard from '../../../assets/svg/dms_card.svg';
import insurance_small from '../../../assets/svg/insurance_small.svg';
import sameYearDetect from '../../../functions/sameYearDetect';
import ProgramDate from '../ProgramDate/ProgramDate';

type Props = {
  benefit?: Benefit;
  isConnectedToEmployee? :boolean;
  wide?: boolean;
  onClick?: () => void;
  hideDates?: boolean;
  hidePrice?: boolean;
  blocked? : boolean;
};

const ProgramItem:FC<Props> = ({ benefit, wide = true, isConnectedToEmployee: isConnected = false, onClick, hideDates = false, hidePrice = false, blocked = false }) => {
  const { programs } = benefit;
  const program = programs[0];
  const FULL_DISCOUNT = 100;

  if (!wide) {
    return (
      <ProgramCardSm onClick={onClick}>
        <CardDataSm>
          <CardHeader>
            <Title type="h4">{benefit.title}</Title>
            {!hideDates && (
            <CardDate>
              <ProgramDate benefit={benefit} />
            </CardDate>
            )}
          </CardHeader>
          <CardBodySm>
            {!hidePrice && (
            <>
              {benefit.connectionInfo ? (
                <>
                  {benefit.connectionInfo.offset !== FULL_DISCOUNT
                    ? <CardBadge variant="warning">платно</CardBadge>
                    : <CardBadge variant="success">бесплатно</CardBadge>}
                </>
              ) : (
                <>
                  {program.price && program.price.price > 0
                    ? <CardBadge variant="warning">платно</CardBadge>
                    : <CardBadge variant="success">бесплатно</CardBadge>}
                </>
              )}
            </>
            )}
            {(new Date(benefit.bDate) > new Date() || !isConnected) && <CardBadge variant="neutral">{`подключение на ${new Date(benefit.eDate).getFullYear()}`}</CardBadge>}
          </CardBodySm>
        </CardDataSm>
        <CardIconSm>
          <img alt="" src={insurance_small} />
        </CardIconSm>
      </ProgramCardSm>
    );
  }
  return (
    <ProgramCard onClick={() => { if (!blocked)onClick(); }}>
      {blocked && <div className="disabled" />}
      <CardData>
        <CardHeader>
          <Title type="h3">{benefit.title}</Title>
        </CardHeader>
        <CardBody>

          {!hideDates && <CardDate><ProgramDate benefit={benefit} /></CardDate>}
          {blocked && <CardDate><p>Чтобы подключить эту программу, отключите ДМС от другой страховой компании</p></CardDate>}

          {!hidePrice && (
          <>
            {benefit.connectionInfo ? (
              <>
                {benefit.connectionInfo.offset !== FULL_DISCOUNT
                  ? <CardBadge variant="warning">платно</CardBadge>
                  : <CardBadge variant="success">бесплатно</CardBadge>}
              </>
            ) : (
              <>
                {program.price && program.price.price > 0
                  ? <CardBadge variant="warning">платно</CardBadge>
                  : <CardBadge variant="success">бесплатно</CardBadge>}
              </>
            )}
          </>
          )}
          {(new Date(benefit.bDate) > new Date() || !isConnected) && <CardBadge variant="neutral">{`подключение на ${new Date(benefit.eDate).getFullYear()}`}</CardBadge>}
        </CardBody>
      </CardData>
      <CardIcon>
        <img alt="" src={dmscard} />
      </CardIcon>
    </ProgramCard>
  );
};

export default ProgramItem;
