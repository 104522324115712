import styled from 'styled-components';

export const OffsetProgramsContainer = styled.div`
  margin: 0 0 0 25px;
  display: flex;
  flex-direction: column;
`

export const OffsetProgramsWrapper = styled.div`
  max-width: 100%;
  & * {
    max-width: 100%;
    white-space: break-spaces;
  }
`
