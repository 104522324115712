import { createSelector } from '@reduxjs/toolkit';
import { RootPersist, RootState } from '../store';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { registerDate } from '../../utils/currentRegisterData';

export const getSelectedBenefit = (state: RootPersist) => {
  return state.benefits.selectedBenefit;
};

export const benefitsStateSelector = (state: RootPersist) => {
  return state.benefits;
};

export const formSelector = (state: RootPersist) => {
  return state.benefits.connectForm;
};

export const successOrderSelector = (state: RootPersist) => {
  return state.benefits.successOrder;
};

export const rejectOrderSelector = (state: RootPersist) => {
  return state.benefits.rejectedOrder;
};

export const editFormSelector = (state: RootPersist) => {
  return state.benefits.editOrder;
};

export const notConnectedBenefits = (state: RootPersist) => {
  return state.benefits.benefits.filter((benefit) => !benefit.connected);
};
export const benefitsAfter = (state: RootPersist) => {
  const connectedBenefits = state.benefits.benefits.filter((item) => item.connected);
  return state.benefits.benefits
    .filter((item) => {
      // const userBenefit = !userBenefits.includes(item.title);
      // if (userBenefit) {
      let currentBenefit = connectedBenefits.find(((cBenefit) => {
        return cBenefit.title === item.title;
      }));
      if (currentBenefit) {
        if (new Date(currentBenefit?.eDate).getFullYear() < new Date(item.eDate).getFullYear()) {
          return true;
        } else {
          return false;
        }
      }
      // }
      return true;
    })
    .filter((benefit) => {
      if ((isAfter(new Date(), registerDate().endDAte) || isSameDay(new Date(), registerDate().endDAte))
        || isBefore(new Date(), registerDate().startDate) && isBefore(new Date(), new Date(benefit.showUntil)) && !benefit.connected) {
        return true;
      }
      return !benefit.connected && isAfter(new Date(benefit.activationCondition.registrationUntil), new Date())
    })

};

export const connectedBenefits = (state: RootPersist) => {
  return state.benefits.benefits.filter((benefit) => benefit.connected);
};

export const permanentBonuses = (state: RootPersist) => {
  return state.benefits.benefits.filter((benefit) => benefit.type === 'base').sort((itemA, itemB) => itemA.isFeed ? -1 : 1);
};

export const hasRedirectUrl = (state: RootPersist) => {
  return state.benefits?.redirectUrl;
}

export const allBenefitsSelector = (state: RootPersist) => {
  return state;
};

export const allAvailableBenefits = (state: RootPersist) => {
  return state.benefits.benefits;
}

export const userBenefitSelector = (state: RootPersist) => {
  return state.benefits.userBenefits;
};

export const selectBenefitById = (id: string, child = '') => {
  return createSelector(allBenefitsSelector, (allBenefits) => {
    // if (child) {
    //   const parent = allBenefits.benefits.benefits.find((benefit) => benefit._id === child);
    //   return parent.children.find((benefit) => benefit._id === id);
    // }
    return allBenefits.benefits.benefits.find((benefit) => benefit._id === id);
  })
};

export const isInUserBenefits = (id: string) => {
  return createSelector(userBenefitSelector, (userBenefits) => {
    return userBenefits.find((uBenefit) => uBenefit.benefitData.benefit === id);
  })
};

export const parentInUserBenefits = (ids: string[]) => {
  return createSelector(userBenefitSelector, (userBenefits) => {
    return userBenefits.find((uBenefit) => ids.includes(uBenefit.benefitData.benefit));
  })
};

export const currentOrderSelector = (state: RootPersist) => {
  return state.benefits.currentOrder;
};

export const currentBenefitSelectorById = (id) => {
  return createSelector(allBenefitsSelector, (userBenefits) => {
    return userBenefits.benefits.benefits
      .find((benefit) => benefit._id === id);
  });
};

export const currentBenefitSelector = (state: RootPersist) => {
  return state.benefits.currentBenefit;
};

export const currentBenefitWithFilteredPrograms = (state: RootPersist) => {
  if (state.benefits.currentBenefit) { }
  return {
    ...state.benefits.currentBenefit,
    programs: state?.benefits?.currentBenefit?.programs?.filter((program) => program.type !== 'info') || [],
  }
}

export const connectedProgramSelector = (benefitId: string) => {
  return createSelector(userBenefitSelector, (state) => {
    const userBenefit = state.find((uBenefit) => benefitId === uBenefit.benefitData.benefit)
    return userBenefit.benefitData.program;
  })
};

export const programsByBenefitId = (id: string) => {
  return createSelector(allBenefitsSelector, (allBenefits) => {
    return allBenefits.benefits.benefits.find((benefit) => benefit._id === id)?.programs || [];
  })
}
