// Core
import React, { createContext, FC, ReactNode, useContext } from 'react';
import { User } from 'oidc-client';

// Store
import { isLoggedIn } from '../redux/selectors/person.selector';
import { dropData } from '../redux/slices/benefitsSlice';
import { dropUser, setLoggedOut } from '../redux/slices/personSlice';
import { fetchPrograms } from '../redux/thunks/fetchPrograms';
import { fetchPerson } from '../redux/thunks/personThunk';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

type AuthCtx = {
  loggedIn: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  signIn: () => void,
  fetchData: () => void,
  logOut: () => void,
  getToken: (token: string) => User,
  isTokenExpired: (token: string) => boolean,
};

const authContext = createContext({} as AuthCtx);

const useProvideAuth = () => {
  const dispatch = useAppDispatch();

  const loggedIn = useAppSelector(isLoggedIn);

  const signIn = async () => dispatch(await fetchPerson());

  const fetchData = async () => dispatch(await fetchPrograms());

  const logOut = () => {
    dispatch(dropUser());
    dispatch(dropData());
  };

  const getToken = (access_token: string): User | null => {
    const entry = Object.entries(sessionStorage)
      .find(([k, value]) => k.includes('oidc.user') && value.includes(access_token));
    if (!entry?.[1]) return null;
    return JSON.parse(entry[1]) as User;
  };

  const isTokenExpired = (token: string): boolean => {
    if (token?.length) {
      const userToken = getToken(token);
      if (!userToken) return true;
      return userToken.expires_at * 1000 - new Date().getTime() <= 0;
    }
    return false;
  };

  return {
    loggedIn,
    fetchData,
    signIn,
    logOut,
    getToken,
    isTokenExpired,
  };
};

type PAProps={
  children:ReactNode
};

export const ProvideAuth: FC<PAProps> = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
};

export const useAppAuth = () => {
  return useContext(authContext);
};
