/* eslint-disable no-underscore-dangle */
// Core
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Search } from '@skbkontur/react-icons';

// Components
import { Input } from '@skbkontur/react-ui';
import PersonDev from '../../components/PersonDev';

// Functions
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useDebounce from '../../functions/useDebounce';

// Store
import { selectLoaderStatus } from '../../redux/selectors/global.selector';
import { getAllPersons } from '../../redux/selectors/person.selector';
import { disableLoader, setLoader } from '../../redux/slices/globalSlice';
import { fetchAllPersons } from '../../redux/thunks/personThunk';

// Styles
import { ChoosePersonWrapper, InputWRapper } from './ChoosePersonDev.styled';

const DevPageBody = styled.div`
  background-color:#fff;
  padding: 0 124px;
  height: 100%;
`;

const ChoosePersonDevPage = () => {
  const [subId, setSubId] = useState('');
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce<string>(search, 2000);

  const dispatch = useAppDispatch();
  const allPersons = useAppSelector(getAllPersons);
  const loader = useAppSelector(selectLoaderStatus);
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const handleChange = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    async function fetchData() {
      await dispatch(setLoader());
      await dispatch(fetchAllPersons(search));
      await dispatch(disableLoader());
    }
    setSubId(query.get('subId'));
    fetchData();
  }, [debouncedValue]);
  return (
    <DevPageBody>
      <>
        <InputWRapper>
          <Input leftIcon={<Search />} value={search} onValueChange={handleChange} placeholder="ФИО или email сотрудника" />
        </InputWRapper>
        {allPersons.length > 0 && !loader && (
          <ChoosePersonWrapper>
            {allPersons.map((person) => (
              <PersonDev subId={subId} key={person._id} person={person} />
            ))}
          </ChoosePersonWrapper>
        )}
      </>
    </DevPageBody>
  );
};

export default ChoosePersonDevPage;
