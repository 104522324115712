import React, { FC } from 'react';
import { ComboBox, ComboBoxExtendedItem } from '@skbkontur/react-ui';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import Benefit from '../../../../../../interfaces/benefit.interface';
import BenefitOption from '../../../../../../components/Select/BenefitOption/BenefitOption';
import Box from '../../../../../../components/Box/Box';

export type BenefitOptions = {
  value: Benefit;
  label: string;
  date?: string;
};

type Props = {
  benefits: Benefit[],
  value: BenefitOptions,
  onChange: (value: BenefitOptions) => void,
  disabled?: boolean;
  onInputValueChange?: () => void
};

const dateFormat = 'dd MMMM yyyy';

const BenefitsSelect:FC<Props> = ({ benefits, value, onChange, disabled = false, onInputValueChange }) => {
  const options = benefits.map((benefit) => {
    const beginDate = new Date(benefit.bDate);
    const endDate = new Date(benefit.eDate);
    return {
      value: benefit,
      label: benefit.title,
      date: `${format(beginDate, dateFormat, { locale: ru })} – ${format(endDate, dateFormat, { locale: ru })}`,
    };
  });

  const getOptions = (q:string) => {
    return Promise.resolve(
      options.filter((x) => x.label.toLowerCase().includes(q.toLowerCase()) || x.date.toLowerCase().includes(q.toLowerCase())),
    ) as unknown as Promise<ComboBoxExtendedItem<BenefitOptions>[]>;
  };

  const customRenderOption = (option: BenefitOptions) => {
    return <BenefitOption benefitTitle={option.label} date={option.date} />;
  };

  return (
    <Box>
      <ComboBox onInputValueChange={onInputValueChange} disabled={disabled} width="100%" renderItem={customRenderOption} value={value} onValueChange={onChange} getItems={getOptions} />
    </Box>
  );
};

export default BenefitsSelect;
