/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { Radio, RadioGroup } from '@skbkontur/react-ui';
import Benefit from '../../interfaces/benefit.interface';
import OrderDTO from '../../interfaces/orderDTO.interface';
import { RadioGroupWrapper, RadioLabel, RadioLabelSub } from './AvailablePrograms.styled';

type PropTypes = {
  benefit: Benefit;
  order: OrderDTO;
  isFree: boolean;
  handleValueChange?: any; // TODO: Refactor Пока так
};

const Program: FC<PropTypes> = ({ benefit, order, isFree, handleValueChange }) => {
  return (
    (benefit.programs.length > 1) ? (
      <RadioGroup
        value={order.program}
      >
        <RadioGroupWrapper>
          <span>Программы</span>
          {benefit.programs.map((program) => {
            const isFreeProgram = program.offsetCondition.find((offset) => isFree && offset.offset === 100);
            return (
              <>
                <Radio
                  checked={order.program === program._id}
                  onClick={(e) => handleValueChange(e)}
                  value={program._id}
                  key={program._id}
                  name={program._id}
                >
                  <RadioLabel>
                    <span>{program.title}</span>
                    {program.description && (
                      <RadioLabelSub>
                        {program.description}
                      </RadioLabelSub>
                    )}
                    {!isFreeProgram && (
                      <RadioLabelSub>
                        <NumberFormat value={Math.round(program.price.price)} displayType="text" thousandSeparator={' '} />
                        {' '}
                        {program.sign ? program.sign : '₽'}
                      </RadioLabelSub>
                    )}
                  </RadioLabel>
                </Radio>
              </>
            );
          })}
        </RadioGroupWrapper>
      </RadioGroup>
    ) : null
  );
};

export default Program;
