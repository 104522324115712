import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { ThemeContext } from '@skbkontur/react-ui';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { persistor, store } from './redux/store';
import { myFlatTheme } from './functions/theme';
import { ProvideAuth } from './functions/use-auth';
import { ProvideChannel } from './functions/channelContext';
import TopPageMiddleware from './components/TopPageMiddleware';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeContext.Provider value={myFlatTheme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <TopPageMiddleware>
              <ProvideAuth>
                <ProvideChannel>
                  <App />
                </ProvideChannel>
              </ProvideAuth>
            </TopPageMiddleware>
          </PersistGate>
        </Provider>
      </ThemeContext.Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
