import styled from 'styled-components';
import { ProgramPageContent } from '../../pages/Program/Program-page.styled';

export const LayoutWrapper = styled.div`
  background: #f6f6f6;
  padding: 0 124px;

  ${ProgramPageContent} {
    padding: 32px 124px;
    margin: 0 -124px;
  }
`;

export const p = styled.p`
`;
