/* eslint-disable no-underscore-dangle */
import { ArrowChevronDown } from '@skbkontur/react-icons';
import React, { FC, useState, useRef } from 'react';
import Benefit from '../../interfaces/benefit.interface';
import Program from '../../interfaces/program.interface';
import HeadingStatus from '../HeadingStatus';
import InfoTag from '../InfoTag';
import Title from '../Title';
import { AccordionContent, AccordionHeading, AccordionHeadingContent, AccordionHeadingHeader, AccordionWrapper } from './BenefitAccordion.styled';

type PropTypes = {
  benefit: Benefit;
  program: Program;
};

const BenefitAccordion: FC<PropTypes> = ({ benefit, program }) => {
  const [isActive, setIsActive] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const setAndScroll = () => {
    setIsActive(!isActive);
    if (contentRef.current && !isActive) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AccordionWrapper>
      <AccordionHeading>
        <AccordionHeadingHeader
          onClick={() => setAndScroll()}
          active={isActive}
        >
          <ArrowChevronDown size={32} />
          <Title type="h2">
            {benefit.title}
          </Title>
          <InfoTag connected={benefit.connected} benefitId={benefit._id} child={benefit.parent} programs={benefit.programs} />
        </AccordionHeadingHeader>
        <AccordionHeadingContent>
          <HeadingStatus benefit={benefit} isAdditionalPackage />
        </AccordionHeadingContent>
      </AccordionHeading>
      <AccordionContent ref={contentRef} contentHeight={contentRef?.current?.scrollHeight} active={isActive} dangerouslySetInnerHTML={{ __html: program.content && program.content.replace(/\\"/g, '"') }} />
    </AccordionWrapper>
  );
};

export default BenefitAccordion;
