import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cities from '../../interfaces/fetch-cities.intarface';
import { fetchCities } from '../thunks/citiesThunk';

type GlobalSlice = {
  loading: boolean;
  token: string;
  cities: Cities;
  citiesLoading: boolean;
  showCityModal: boolean;
  mainProgramCity: string | null;
}

const initialState: GlobalSlice = {
  loading: false,
  token: '',
  cities: {} as Cities,
  citiesLoading: false,
  showCityModal: false,
  mainProgramCity: null,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLoader(state) {
      state.loading = true;
    },
    disableLoader(state) {
      state.loading = false;
    },
    setToken(state, action: PayloadAction<{ token: string }>) {
      state.token = action.payload.token;
    },
    handleCityModal(state) {
      state.showCityModal = !state.showCityModal;
    },
    setPersonMainProgramCity(state, action: PayloadAction<{ city: string }>) {
      state.mainProgramCity = action.payload.city;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCities.pending, (state) => {
      state.citiesLoading = true;
    });
    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.cities = action.payload;
      state.citiesLoading = false;

    });
    builder.addCase(fetchCities.rejected, (state) => {
      state.citiesLoading = false;
    });
  }
});

export const { setLoader, disableLoader, setToken, handleCityModal, setPersonMainProgramCity } = globalSlice.actions;
export default globalSlice.reducer;
