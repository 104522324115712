import React, { FC } from 'react';
import { Loader } from '@skbkontur/react-ui';
import { useAppSelector } from '../../../../redux/hooks';
import { getConnectableEmployeeBenefits, getConnectedEmployeeBenefits, getCurrentEmployeeBenefits, getEmployeeBenefitsLoading, getFutureEmployeeBenefits } from '../../../../redux/selectors/employee.selector';
import ProgramList from '../../../../components/ProgramList/ProgramList';
import ProgramItem from '../../../../components/ProgramList/ProgramItem/ProgramItem';
import Benefit from '../../../../interfaces/benefit.interface';
import Title from '../../../../components/Title';

type Props = {
  onBenefitCLick: (benefit:Benefit) => void
};

const ConnectableBenefits:FC<Props> = ({ onBenefitCLick }) => {
  const connectableEmployeeBenefits = useAppSelector(getConnectableEmployeeBenefits);
  const connectedEmployeeBenefits = useAppSelector(getConnectedEmployeeBenefits);
  const connectedEmployeeDMSs = connectedEmployeeBenefits.filter((bnft) => bnft.type === 'DMS');

  const connectableDMS = connectableEmployeeBenefits.filter((benefit) => benefit.type === 'DMS');
  const connectableOthers = connectableEmployeeBenefits.filter((benefit) => benefit.type !== 'DMS');

  const isLoading = useAppSelector(getEmployeeBenefitsLoading);

  return (
    <>
      <Title type="h2">Можно подключить</Title>
      <Loader active={isLoading} delayBeforeSpinnerShow={0}>
        <ProgramList>
          {connectableDMS.map((benefit) => (
            <ProgramItem
              key={`${benefit._id}3`}
              wide
              benefit={benefit}
              onClick={() => {
                onBenefitCLick(benefit);
              }}
              hideDates
              hidePrice
              blocked={connectedEmployeeDMSs.filter((bnft) => new Date(bnft.eDate).getFullYear() === new Date(benefit.eDate).getFullYear()).length > 0}
            />
          ))}
        </ProgramList>
      </Loader>
      <Loader active={isLoading} delayBeforeSpinnerShow={0}>
        <ProgramList>
          {connectableOthers.map((benefit) => (
            <ProgramItem
              key={`${benefit._id}3`}
              wide={false}
              benefit={benefit}
              onClick={() => {
                onBenefitCLick(benefit);
              }}
              hideDates
              hidePrice
            />
          ))}
        </ProgramList>
      </Loader>
    </>
  );
};

export default ConnectableBenefits;
