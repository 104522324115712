import styled from 'styled-components';

export const PersonCityWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  transition: all .4s ease;
  margin: 0 0 0 -4px;

  &:hover {
    background: #0000000F;
  }
  & svg {
    margin: 0 0 0 8px;
  }
`