/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
import { Delete } from '@skbkontur/react-icons';
import { Button, Toast, SidePage, Tooltip } from '@skbkontur/react-ui';
import format from 'date-fns/format';
import { ru } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { currentBenefitSelector, currentOrderSelector, editFormSelector, formSelector, successOrderSelector, userBenefitSelector } from '../../redux/selectors/benefits.selector';
import { hideForm, setReconnectionProgram } from '../../redux/slices/benefitsSlice';
import { addProgram, deleteProgram, saveProgram } from '../../redux/thunks/fetchPrograms';
import InfoTag from '../InfoTag';
import SidePageAccordion from '../SidePageAccordion';
import SuccessSubscribe from '../SuccessSubscribe';
import Title from '../Title';
import { FooterButtons, SidePageMainHeading, SidePageOverHeader, SidePageWrapper, StyledButton, TooltipMenu, SidePageOverFooter } from './SidePageProgram.styled';
import { useChannelContext } from '../../functions/channelContext';
import { useAppDispatch } from '../../redux/hooks';

const SidePageProgram = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const channel = useChannelContext();

  const currentOrder = useSelector(currentOrderSelector);
  const isOpened = useSelector(formSelector);
  const isEdit = useSelector(editFormSelector);
  const successOrder = useSelector(successOrderSelector);
  const benefit = useSelector(currentBenefitSelector);
  const currentBenefit = useMemo(() => ({
    ...benefit,
    programs: benefit?.programs?.filter((program) => program.type !== 'info') || [],
  }), [benefit]);
  const [isValid, setIsValid] = useState(false);
  const [isShowShadow, setIsShowShadow] = useState(false);
  const [isAddButtonBlocked, setIsAddButtonBlocked] = useState(false);

  useEffect(() => {
    if (currentOrder.benefit && (currentOrder.offset >= 0)
      && currentOrder.program
      && ((currentOrder.offset === 0 && currentOrder.creditPlan !== null) || currentOrder.offset === 100)
      && ((currentOrder.offset === 0 && currentOrder.signatureUrl?.length) || currentOrder.offset === 100)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [currentOrder]);

  const handleCancel = () => {
    dispatch(hideForm());
  };

  const handleAddProgram = () => {
    setIsAddButtonBlocked(true);
    dispatch(setReconnectionProgram(false));
    dispatch(addProgram(currentOrder));
  };

  const handleSaveProgram = () => {
    dispatch(saveProgram(currentOrder));
    dispatch(hideForm());
    Toast.push('Программа обновлена');
    channel.sendMessage('program_connected');
    navigate('/');
  };

  const handleDeleteProgram = () => {
    dispatch(deleteProgram({ id: currentOrder._id }));
    dispatch(hideForm());
    Toast.push('Программа отключена', {
      label: 'Отмена',
      handler: () => {
        dispatch(addProgram(currentOrder));
        dispatch(setReconnectionProgram(true));
        Toast.push('Отключение программы отменено');
      },
    });
    setTimeout(() => {
      channel.sendMessage('program_connected');
      navigate('/');
    }, 4000);
  };

  const handleCloseSidepage = () => {
    if (successOrder) {
      dispatch(hideForm());
      setIsAddButtonBlocked(false);
      navigate('/');
    } else {
      dispatch(hideForm());
      setIsAddButtonBlocked(false);
    }
  };

  if (isOpened) {
    window.addEventListener('popstate', handleCancel);
  } else {
    window.removeEventListener('popstate', handleCancel);
  }

  const tooltipRender = () => (
    <TooltipMenu>
      Программа будет отключена. Подключить заново можно до
      {' '}
      {format(new Date(currentBenefit.activationCondition.registrationUntil), 'dd MMMM', { locale: ru })}
      <StyledButton onClick={() => handleDeleteProgram()} use="danger">Отключить программу</StyledButton>
    </TooltipMenu>
  );

  if (isOpened) {
    return (
      <SidePageWrapper
        width="496px"
        blockBackground
        onClose={handleCloseSidepage}
        style={{
          height: '100%',
          overflowX: 'hidden',
        }}
      >
        <SidePageOverHeader
          sticky={false}
        >
          {!successOrder && (
            <SidePageMainHeading>
              <Title type="h4">
                <>
                  <Title type="h4">{currentBenefit.title}</Title>
                  {'　'}
                  <InfoTag connected={currentBenefit.connected} benefitId={benefit._id} child={benefit.parent} small programs={currentBenefit.programs} />
                </>
              </Title>
            </SidePageMainHeading>
          )}
        </SidePageOverHeader>
        {!successOrder && (
          <>
            <SidePage.Body style={{ height: '100%' }}>
              <SidePageAccordion benefit={currentBenefit} />
              <SidePageOverFooter isShowShadow={isShowShadow}>
                <FooterButtons>
                  {isEdit && (
                    <Button
                      onClick={() => { handleSaveProgram(); }}
                      disabled={!isValid}
                      use="primary"
                      size="medium"
                    >
                      Сохранить
                    </Button>
                  )}
                  {!isEdit && (
                    <Button
                      onClick={() => { handleAddProgram(); }}
                      disabled={isAddButtonBlocked || !isValid}
                      use="primary"
                      size="medium"
                    >
                      Подключить
                    </Button>
                  )}
                  <Button
                    onClick={() => handleCancel()}
                    use="default"
                    size="medium"
                  >
                    Отменить
                  </Button>
                  {isEdit && (
                    <Tooltip pos="top right" render={tooltipRender} trigger="click">
                      <Button
                        icon={<Delete size={18} />}
                        use="default"
                        size="medium"
                      >
                        Отключить
                      </Button>
                    </Tooltip>
                  )}
                </FooterButtons>
              </SidePageOverFooter>
            </SidePage.Body>
          </>
        )}
        {
          successOrder && (
            <SuccessSubscribe onButtonBlocked={setIsAddButtonBlocked} />
          )
        }
      </SidePageWrapper>
    );
  }

  return (<> </>);
};

export default SidePageProgram;
