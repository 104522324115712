/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable-next-line no-fallthrough */
import React from 'react';
import { useRouteError } from 'react-router';
import { ErrorPageWrapper, PageTitle, ErrorText, ErrorCode, PageContent, LinkDecorator } from './ErrorPage.styled';

const ErrorPage = ({ errorCode }) => {
  let errorPageContent;

  switch (errorCode) {
    case '400':
      errorPageContent = {
        errorCode: 400,
        errorText: 'Ошибка на странице',
        pageDescription: <PageContent>Мы знаем о проблеме и работаем над ее решением.</PageContent>,
      };
      break;

    case '403':
      errorPageContent = {
        errorCode: 403,
        errorText: 'Доступ запрещен',
        pageDescription: <PageContent>Мы знаем о проблеме и работаем над ее решением.</PageContent>,
      };
      break;

    case '500':
      errorPageContent = {
        errorCode: 500,
        errorText: 'Сервис недоступен',
        pageDescription: <PageContent>Мы знаем о проблеме и работаем над её решением.<br />Попробуйте обновить страницу позже.</PageContent>,
      };
      break;

    case '404':
    default:
      errorPageContent = {
        errorCode: 404,
        errorText: 'Страница не найдена',
        pageDescription: <PageContent>Мы знаем о проблеме и работаем над ее решением.</PageContent>,
      };
  }

  return (
    <ErrorPageWrapper>
      <PageTitle>Социальные программы</PageTitle>
      <ErrorText>
        {errorPageContent.errorText}
        <ErrorCode>{errorPageContent.errorCode}</ErrorCode>
      </ErrorText>
      {errorPageContent.pageDescription}
      {errorPageContent.errorCode !== 500 && (
        <PageContent>
          Подать заявку на подключение можно здесь
        </PageContent>
      )}
      {errorPageContent.errorCode === 404 && (
        <LinkDecorator>
          <a href="https://cabinet.skbkontur.ru/help/incidents/create?serviceId=599294209">Кабинет сотрудника</a>
        </LinkDecorator>
      )}
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
