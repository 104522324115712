import { isSameYear } from 'date-fns';

const sameYearDetect = (date: Date, compareDate = new Date): string => {
  const sameYear = isSameYear(new Date(compareDate), new Date(date));
  if (sameYear) {
    return 'dd MMMM';
  }
  return 'dd MMMM yyyy';
};

export default sameYearDetect;
