import React from 'react';
import { Route } from 'react-router-dom';

const RouteWrapper = ({
  // @ts-ignore
  layout: Layout,
  // @ts-ignore
  component: Component,
  ...rest
}) => {
  return (
    <>
      <Layout>
        <Component />
      </Layout>
    </>
  );
};

export default RouteWrapper;
