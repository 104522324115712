import { Select } from '@skbkontur/react-ui';
import styled from 'styled-components';

export const ModalCitySelect = styled(Select)`
  & button {
    border-radius: 2px;
  }
`

export const ModalCitySelectItem = styled.span`
  color: var(--secondary-text);
`

export const CityDescription = styled.div`
  padding: 16px;
  background: #CDEDFF;
  border-radius: 8px;
  margin: 8px 0;
  p {
    margin: 0;
  }
`