import React, { FC } from 'react';
import { TagText, TagWrapper, SubAdds } from './InfoTag.styled';

type PropTypes = {
  text: string;
};

const InfoTagSimple: FC<PropTypes> = ({ text }) => {
  return (
    <TagWrapper>
      <TagText isFree small>
        {`${text}₽ в сутки`}
      </TagText>

      <SubAdds>до вычета НДФЛ</SubAdds>
    </TagWrapper>
  );
};

export default InfoTagSimple;
