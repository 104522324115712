import React, { FC } from 'react';
import { Loader } from '@skbkontur/react-ui';
import { useAppSelector } from '../../../../redux/hooks';
import { getConnectedEmployeeBenefits, getCurrentEmployeeBenefits, getEmployeeBenefitsLoading, getFutureEmployeeBenefits } from '../../../../redux/selectors/employee.selector';
import ProgramList from '../../../../components/ProgramList/ProgramList';
import ProgramItem from '../../../../components/ProgramList/ProgramItem/ProgramItem';
import Benefit from '../../../../interfaces/benefit.interface';
import Title from '../../../../components/Title';
import ProgramStub from '../../../../components/ProgramStub';

type Props = {
  onBenefitCLick: (benefit:Benefit) => void
};

const ConnectedBenefits:FC<Props> = ({ onBenefitCLick }) => {
  const connectedEmployeeBenefits = useAppSelector(getConnectedEmployeeBenefits);
  const currentEmployeeBenefits = useAppSelector(getCurrentEmployeeBenefits);
  const futureEmployeeBenefits = useAppSelector(getFutureEmployeeBenefits);
  const isLoading = useAppSelector(getEmployeeBenefitsLoading);
  if (connectedEmployeeBenefits.length < 1) return <Loader active={isLoading} delayBeforeSpinnerShow={0}><ProgramStub title="Пока нет программ" /></Loader>;

  return (
    <>
      <Title type="h2">Подключенные программы</Title>

      {currentEmployeeBenefits.length > 0
          && (
          <Loader active={isLoading} delayBeforeSpinnerShow={0}>
            <ProgramList>
              {currentEmployeeBenefits.map((benefit) => (
                <ProgramItem
                  key={`${benefit._id}1`}
                  wide={benefit.type === 'DMS' || benefit.type === 'vzr' || false}
                  isConnectedToEmployee
                  benefit={benefit}
                  onClick={() => {
                    onBenefitCLick(benefit);
                  }}
                />
              ))}
            </ProgramList>
          </Loader>
          )}
      {futureEmployeeBenefits.length > 0
          && (
            <Loader active={isLoading} delayBeforeSpinnerShow={0}>
              <ProgramList>
                {futureEmployeeBenefits.map((benefit) => (
                  <ProgramItem
                    key={`${benefit._id}2`}
                    wide={benefit.type === 'DMS' || benefit.type === 'vzr' || false}
                    isConnectedToEmployee={false}
                    benefit={benefit}
                    onClick={() => {
                      onBenefitCLick(benefit);
                    }}
                  />
                ))}
              </ProgramList>
            </Loader>
          )}
    </>
  );
};

export default ConnectedBenefits;
