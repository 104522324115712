import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../functions/api";
import Person from "../../interfaces/person.interface";
import Benefit from "../../interfaces/benefit.interface";

export const fetchEmployee = createAsyncThunk(
    'employee/fetch',
    async ( employeeId?:string ) => {
      const { data }: { data: Person } = await client.get(`/api/admin/person/${employeeId}`);
      return data;
    }
)

export const fetchEmployeeBenefits = createAsyncThunk(
  'employee-benefits/fetch',
  async ( employeeId?:string ) => {
    const { data }: { data: { connected: Benefit[], benefits:Benefit[] } } = await client.get(`/api/admin/programs/${employeeId}`);
    return data;
  }
)

export const fetchAllBenefits = createAsyncThunk(
  'all-benefits/fetch',
  async ( query: {cityId:string, searchTitle:string, year: number}  ) => {
    const { data }: { data: Benefit[] } = await client.get(`/api/admin/programs`, {
      params: query
    });
    return data;
  }
)

export const connectBenefitToEmployee = createAsyncThunk(
  'employee-benefit/connect',
  async ( connectBenefit:any ) => {
    const { data }: { data: Benefit[] } = await client.post(`/api/admin/create-order`, connectBenefit);
    return data;
  }
)

export const updateBenefitToEmployee = createAsyncThunk(
  'employee-benefit/update-connect',
  async ( updateData:{updateBenefit:any, userId:string} ) => {
    const { updateBenefit, userId } = updateData;
    const { data }: { data: Benefit[] } = await client.put(`/api/admin/update-order/${userId}`, updateBenefit);
    return data;
  }
)

export const disconnectBenefitFromEmployee = createAsyncThunk(
  'employee-benefit/disconnect',
  async ( deleteData:{benefitId: string, userId:string} ) => {
    const {benefitId, userId} = deleteData;
    const { data }: { data: Benefit[] } = await client.delete(`/api/admin/${benefitId}/${userId}`,);
    return data;
  }
)