import { Loader } from '@skbkontur/react-ui';
import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLoaderStatus } from '../redux/selectors/global.selector';
import { isLoggedIn, isLoggedInByClient } from '../redux/selectors/person.selector';
import Auth from '../components/Auth';

const PrivateRoute = ({
  // @ts-ignore
  layout: Layout,
  // @ts-ignore
  component: Component,
  ...rest }) => {
  const isSignedIn = useSelector(isLoggedIn);
  // const isSignedIn = true;
  const isLoading = useSelector(selectLoaderStatus);

  return (
    <>
      {isLoading && (
        <Loader type="big" active={isLoading} />
      )}

      {isSignedIn && (
        <Auth>
          <Layout>
            <Component />
          </Layout>
        </Auth>
      )}
    </>
  );
};

export default PrivateRoute;
