import styled from 'styled-components';

export const DevLayoutWrapper = styled.div`
  height: 100%;
`;

export const DevLayoutHeaderWrapper = styled.div`
  padding: 0 124px
`;

export const LayoutHeading = styled.h2`
  font: 400 32px/40px LabGrotesqueK, sans-serif;
  margin: 0;
`;

export const LayoutParagraph = styled.p`
  font: 400 16px/20px LabGrotesqueK, sans-serif;
  color: rgba(0, 0, 0, .6);
  margin: 0;
`;
