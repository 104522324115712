import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../functions/api';
import Person from '../../interfaces/person.interface';

interface SubPerson {
  subId: string;
  person: string;
}

export const fetchPerson = createAsyncThunk<Person>(
  'person/fetch',
  async (dispatch, state) => {
    const { data }: { data: Person } = await client.get('/api/user');
    return data;
  }
)

export const fetchMe = createAsyncThunk<Person>(
  'person/me',
  async () => {
    const { data }: { data: Person } = await client.get('api/person/me');
    return data;
  }
)

export const updateMe = createAsyncThunk(
  'person/update',
  async (dataToSet: {
    [year: string]: {
      connectedItProgram?: boolean
      connectedAdditionalProgramme?: boolean;
    },
  }, state) => {
    return dataToSet;
  }
)

export const fetchAllPersons = createAsyncThunk(
  'persons/fetch',
  async (search?: string) => {
    const { data }: { data: {persons:Person[], count:number}} = await client.get(`/api/person/persons?search=${search}`);
    return data;
  }
)

export const createSubPerson = createAsyncThunk(
  'persons/create',
  async (personData: SubPerson, state) => {
    const { data } = await client.post('/api/person/create-sub', personData);
    return data;
  }
)

export const dropUserData = createAsyncThunk(
  'persons/logout',
  async () => {

    await client.post('/api/log-out', {});
  }
)

export const deletePerson = createAsyncThunk(
  'persons/create',
  async () => {
    const { data } = await client.delete('/api/person/delete-sub');
    return data;
  }
)

export const deleteAllPersons = createAsyncThunk(
  'persons/deleteAll',
  async () => {
    const { data } = await client.get('/api/person/delete-all');
    return data;
  }
)
