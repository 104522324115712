import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { permanentBonuses } from '../../../redux/selectors/benefits.selector';
import InfoProgram from '../../../components/InfoProgram';
import Title from '../../../components/Title';
import { InfoPageWrapper, InfoProgramsWrapper } from './InfoPrograms.styled';

const InfoPrograms: FC = () => {
  const allBonuses = useSelector(permanentBonuses);
  return (
    <InfoPageWrapper>
      <Title type="h2">
        Действуют всегда
      </Title>
      <InfoProgramsWrapper>
        {allBonuses && allBonuses.map((program) => (
          <InfoProgram key={program._id} program={program} />
        ))}
      </InfoProgramsWrapper>
    </InfoPageWrapper>

  );
};

export default InfoPrograms;
