import React, { FC } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import Benefit from '../../../interfaces/benefit.interface';

type Props = {
  benefit: Benefit;
};

const ProgramDate:FC<Props> = ({ benefit }) => {
  const dateFormat = 'dd MMMM yyyy';
  const beginDate = new Date(benefit.bDate);
  const endDate = new Date(benefit.eDate);

  return (
    <p>
      {format(beginDate, dateFormat, { locale: ru })}
      {' – '}
      {format(endDate, dateFormat, { locale: ru })}
    </p>
  );
};

export default ProgramDate;
