import styled from 'styled-components';

export const ProgramPageHeadingStatus = styled.div`
  margin-top: -10px;
  font: var(--status-text);
`;

export const ProgramPageHeadingP = styled.p<{ disabled?: boolean}>`
  margin-top: 0;
  color: var(--${(props) => (props.disabled ?  'inactive-black' : 'black')});
`

export const ProgramPageHeadingPWithColor = styled.p`
  margin-top: 0;
  color: #EF8B17;
`
export const ProgramPageCanEdit = styled.div<{ isAdditionalPackage?: boolean }>`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    ${ProgramPageHeadingP} {
      margin: 0 0 0 16px;
    }
  }
  & > :first-child {
    margin: ${(props) => (props.isAdditionalPackage ?  '6px 0 10px' : '5px 0 24px')};
  }
`