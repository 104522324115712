import React, { FC, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Radio, RadioGroup } from '@skbkontur/react-ui';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroupWrapper, RadioLabel } from '../../AvailablePrograms/AvailablePrograms.styled';
import { allAvailableBenefits, currentOrderSelector } from '../../../redux/selectors/benefits.selector';
import { RadioLabelSub } from '../SidePageAccordion.styled';
import { addAdditionalDataToOrder } from '../../../redux/slices/benefitsSlice';
import { ProgramPriceRow } from './AdditionalPrograms.styled';

const AdditionalProgramme: FC = () => {
  const dispatch = useDispatch();
  const currentOrder = useSelector(currentOrderSelector);
  const allBenefits = useSelector(allAvailableBenefits);
  const [someChecked, setSomeChecked] = useState<boolean>(false);

  useEffect(() => {
    if (currentOrder.additionalPrograms.some((item) => item.isChecked)) {
      setSomeChecked(true);
    } else {
      setSomeChecked(false);
    }
  }, [currentOrder.additionalPrograms]);

  const handleValueChange = (benefit: string, programme: string, prefix: string) => {
    dispatch(addAdditionalDataToOrder({ benefitId: benefit, programmeId: programme, prefix }));
  };

  return (
    <div>
      <RadioGroup
        value={0}
      >
        <RadioGroupWrapper>
          <span>Выберите дополнительно еще одну бесплатную программу</span>
          {currentOrder.additionalPrograms.map((program) => {
            const currentProgramme = allBenefits.find((benefit) => benefit._id === program.benefit);
            const freeProgramme = currentProgramme?.programs
              .find((programme) => programme.offsetCondition
                .find((condition) => condition.offset >= 100));
            if (freeProgramme) {
              return (
                <>
                  <Radio
                    checked={program.isChecked}
                    onClick={(e) => handleValueChange(currentProgramme._id, freeProgramme._id, freeProgramme.prefix)}
                    value={program.benefit}
                    key={program.benefit}
                    name={program.benefit}
                  >
                    <RadioLabel>
                      <span>{freeProgramme.title}</span>
                      {freeProgramme && (
                        <ProgramPriceRow>
                          <RadioLabelSub strikethrough={(someChecked && program.isChecked) || !someChecked}>
                            <NumberFormat value={Math.round(freeProgramme.price.price)} displayType="text" thousandSeparator={' '} />
                            {' '}
                            {freeProgramme.sign ? freeProgramme.sign : '₽'}
                          </RadioLabelSub>
                          {(program.isChecked || !someChecked) && (
                            <RadioLabelSub>
                              Бесплатно
                            </RadioLabelSub>
                          )}
                        </ProgramPriceRow>
                      )}
                    </RadioLabel>
                  </Radio>
                </>
              );
            }
            return (
              <>
              </>
            );
          })}
        </RadioGroupWrapper>
      </RadioGroup>
    </div>
  );
};

export default AdditionalProgramme;
