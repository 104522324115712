/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { isBefore } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import calculatePersonProbation from '../../functions/calculateUserExperience';
import Program from '../../interfaces/program.interface';
import {
  allAvailableBenefits,
  selectBenefitById,
  userBenefitSelector,
} from '../../redux/selectors/benefits.selector';
import { personSelector } from '../../redux/selectors/person.selector';
import { PriceTag, TagText, TagWrapper } from './InfoTag.styled';

type PropTypes = {
  programs: Program[];
  small?: boolean;
  connected?: boolean;
  localFree?: boolean;
  benefitId?: string;
  child?: string;
  simple?: boolean;
};

const InfoTag: FC<PropTypes> = ({
  programs,
  connected,
  small = false,
  benefitId,
  child = '',
  localFree = false,
  simple = false,
}) => {
  const [isFree, setIsFree] = useState(null);
  const [price, setPrice] = useState<string>('');
  const [single, setSingle] = useState(true);
  const userBenefits = useSelector(userBenefitSelector);
  // const allBenefits = useSelector(allBenefitsSelector);
  const allBenefits = useSelector(allAvailableBenefits);
  const person = useSelector(personSelector);

  const currentBenefit = useSelector(selectBenefitById(benefitId, child));

  const probation = calculatePersonProbation(
    person,
    connected ? new Date() : new Date(currentBenefit.bDate),
  );

  useEffect(() => {
    let freeProgram;
    programs
      ?.filter((program) => program.type !== 'info')
      .forEach((program) => {
        if (!program.price || program.price.price === 0) {
          freeProgram = true;
          return;
        }
        const freeBenefit = userBenefits.find(
          (item) => item.benefitData.benefit === benefitId
            && item.benefitData.offset === 100,
        );
        if (connected && freeBenefit) {
          freeProgram = true;
        }
        if (!freeProgram) {
          program.offsetCondition.forEach((condition) => {
            if (condition) {
              if (
                (condition.org.length
                  && condition.offset === 100
                  && condition.condition <= probation)
                || (!condition.org.length
                  && condition.offset === 100
                  && condition.condition <= probation)
              ) {
                freeProgram = true;
              }
            }
            return false;
          });
        }
        if (connected && !freeBenefit) {
          freeProgram = false;
        }
      });
    if (freeProgram || localFree) {
      setIsFree(true);
    } else {
      setIsFree(false);
    }
  }, [
    programs,
    userBenefits,
    allBenefits,
    localFree,
    currentBenefit,
    connected,
  ]);
  useEffect(() => {
    if (!connected) {
      setSingle(false);
      return setPrice('платно');
    }
    const programsIds = programs.map((program) => program._id);
    const userPrice = userBenefits.find((uBenefit) => programsIds.includes(uBenefit.benefitData.program));
    setSingle(true);
    if (userPrice) {
      return setPrice(String(Math.round(userPrice.benefitData.price)));
    }
    setPrice(String(Math.round(programs[0].price.price)));
  }, [isFree, connected, userBenefits, currentBenefit, programs, allBenefits]);

  return (
    <TagWrapper>
      {!isFree && single && (
        <TagText isFree={isFree} small={small}>
          <NumberFormat
            value={price}
            displayType="text"
            thousandSeparator={' '}
          />
          &thinsp;
          <PriceTag>₽</PriceTag>
        </TagText>
      )}
      {!isFree && !single && (
        <TagText isFree={isFree} small={small}>
          {price}
          &thinsp;
        </TagText>
      )}
      {isFree && (
        <TagText isFree={isFree} small={small}>
          бесплатно
        </TagText>
      )}
    </TagWrapper>
  );
};

export default InfoTag;
