import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import { Button, Checkbox, Loader, SidePage } from '@skbkontur/react-ui';
import Benefit from '../../../../../../interfaces/benefit.interface';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { citiesSelector } from '../../../../../../redux/selectors/global.selector';
import { getConnectedEmployeeBenefits, getEmployee, getFormBenefits, getFormBenefitsLoading, getIsConnectingBenefit, getIsUpdateEmployeeBenefitSuccessful, getIsUpdatingEmployeeBenefit } from '../../../../../../redux/selectors/employee.selector';
import CitiesSelect from '../formComponents/CitiesSelect';
import BenefitsSelect from '../formComponents/BenefitsSelect';
import Box from '../../../../../../components/Box/Box';
import { FormRow } from '../formComponents/styled';
import ProgramDate from '../../../../../../components/ProgramList/ProgramDate/ProgramDate';
import ProgramTypeRadioGroup from '../formComponents/ProgramRadioGroup';
import PriceRadioGroup, { freePrice } from '../formComponents/PriceRadioGroup';
import Program from '../../../../../../interfaces/program.interface';
import City from '../../../../../../interfaces/city.interface';
import Price from '../../../../../../interfaces/price.interface';
import { getBenefitOption, getCityOption } from '../utils/getOption';
import { connectBenefitToEmployee, fetchAllBenefits } from '../../../../../../redux/thunks/employeeThunk';

type Props = {
  benefit:Benefit;
  onSidepageClose: () => void;
};

type FormSubBenefit = {
  subBenefit: Benefit;
  price?: Price;
};

type FormBenefitData = {
  city?: City;
  benefit?: Benefit;
  program?: Program;
  price?: Price;
  subProgram?: FormSubBenefit;
};

const ConnectBenefitForm:FC<Props> = ({ benefit, onSidepageClose }) => {
  const dispatch = useAppDispatch();

  const { city: employeeCity } = useAppSelector(getEmployee);
  const employee = useAppSelector(getEmployee);
  const cities = useAppSelector(citiesSelector);
  const connectedBenefitsIds = useAppSelector(getConnectedEmployeeBenefits).map((bnft) => bnft._id);
  const connectableBenefits = useAppSelector(getFormBenefits).filter((bnft) => !connectedBenefitsIds.includes(bnft._id));
  const nonItBenefits = connectableBenefits.filter((bnft) => bnft.meta?.isForDevelopers !== true);
  const itBenefits = connectableBenefits.filter((bnft) => bnft.meta?.isForDevelopers === true);
  const connectableBenefitsByUser = benefit.meta?.isForDevelopers ? itBenefits : nonItBenefits;
  const benefitsLoading = useAppSelector(getFormBenefitsLoading);
  const isLoading = useAppSelector(getIsConnectingBenefit);
  const defaultCity = employeeCity || cities.cities[0];

  const defaultFormData = { price: freePrice, city: defaultCity, program: benefit.programs[0] };
  const [formData, setFormData] = useState<FormBenefitData>(defaultFormData);

  useEffect(() => {
    setFormData({ ...defaultFormData });
  }, [benefit]);

  useEffect(() => {
    if (formData.city) {
      dispatch(fetchAllBenefits({ cityId: formData.city._id, searchTitle: benefit.searchTitle, year: new Date(benefit.eDate).getFullYear() }));
    }
  }, [formData.city, benefit]);

  const handleSubmit:FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const data = {
      personId: employee._id,
      benefitId: formData.benefit._id,
      programId: formData.program._id,
      city: formData.city._id,
      offset: formData.price.price > 0 ? 0 : 100,
      price: formData.program.price.price,
      prefix: formData.program.price.code,
      subPrograms: null,
    };

    if (benefit.type === 'DMS' && formData.subProgram) {
      data.subPrograms = [{
        personId: employee._id,
        benefitId: formData.subProgram.subBenefit._id,
        programId: formData.subProgram.subBenefit.programs[0]._id,
        city: formData.city._id,
        price: formData.subProgram.subBenefit.programs[0].price.price,
        prefix: formData.subProgram.subBenefit.programs[0].price.code,
        offset: formData.subProgram.price.price > 0 ? 0 : 100,
      }];
    }

    dispatch(connectBenefitToEmployee(data));
  };

  return (
    <form onSubmit={handleSubmit}>
      <SidePage.Header>{benefit.title}</SidePage.Header>
      <SidePage.Body>
        <SidePage.Container>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
            <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>Город страхования</p>
              <CitiesSelect
                onUnexpectedInput={(value) => {
                  if (value === '') return getCityOption(defaultCity);
                }}
                cities={cities.cities}
                value={getCityOption(formData.city)}
                onChange={({ value }) => {
                  if (value !== formData.city) setFormData({ ...formData, city: value, benefit: null });
                }}
              />
            </FormRow>
            <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>Программа</p>
              <Loader type="mini" active={benefitsLoading} delayBeforeSpinnerShow={0}>
                <BenefitsSelect
                  onInputValueChange={() => {
                    setFormData({ ...formData, benefit: null });
                  }}
                  benefits={formData.city ? connectableBenefitsByUser : []}
                  value={getBenefitOption(formData.benefit)}
                  onChange={({ value }) => {
                    setFormData({ ...formData, benefit: value, program: value.programs[0], price: freePrice, subProgram: null });
                  }}
                />
              </Loader>

            </FormRow>
            {formData.benefit && (
              <>
                <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p>Период страхования</p>
                  <ProgramDate benefit={formData.benefit} />
                </FormRow>
                <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
                  <p>Тип программы</p>
                  <ProgramTypeRadioGroup
                    programs={formData.benefit.programs}
                    value={formData.program}
                    onChange={(value) => {
                      setFormData({ ...formData, program: value, price: freePrice });
                    }}
                  />
                </FormRow>
                {formData.program
                  && (
                  <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
                    <p>Стоимость</p>
                    <PriceRadioGroup programs={[formData.program]} value={formData.price} onChange={(value) => setFormData({ ...formData, price: value })} />
                  </FormRow>
                  )}
                  {formData.benefit.children?.length > 0 && (
                  <FormRow sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
                    <p>Дополнительные программы</p>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                      {formData.benefit.children.map((subBenefit, index) => (
                        <Box key={subBenefit._id} sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                          <Checkbox
                            size="medium"
                            disabled={formData.subProgram && formData.subProgram?.subBenefit._id !== formData.benefit.children[index]._id}
                            checked={formData.subProgram?.subBenefit._id === formData.benefit.children[index]._id}
                            onValueChange={(checked) => {
                              const oldSP = { subBenefit, price: freePrice };
                              if (checked) setFormData({ ...formData, subProgram: oldSP });
                              if (!checked) {
                                setFormData({ ...formData, subProgram: null });
                              }
                            }}
                          >
                            {subBenefit.meta?.isForDevelopers ? `${subBenefit.title} IT` : subBenefit.title}
                          </Checkbox>
                          {formData.subProgram && formData.subProgram?.subBenefit._id === formData.benefit.children[index]._id && (
                          <Box sx={{ marginLeft: 20 }}>
                            <PriceRadioGroup
                              programs={formData.subProgram.subBenefit.programs}
                              value={formData.subProgram.price}
                              onChange={(value) => {
                                setFormData({ ...formData, subProgram: { ...formData.subProgram, price: value } });
                              }}
                            />
                          </Box>
                          )}

                        </Box>
                      ))}
                    </Box>
                  </FormRow>
                  )}
              </>
            )}

          </Box>

        </SidePage.Container>
      </SidePage.Body>
      <SidePage.Footer panel>
        <Box sx={{ display: 'flex', gap: 8 }}>
          <Button disabled={isLoading || !formData.benefit} type="submit" use="primary">Подключить</Button>
          <Button disabled={isLoading} onClick={onSidepageClose}>Отменить</Button>
        </Box>
      </SidePage.Footer>
    </form>
  );
};

export default ConnectBenefitForm;
