import styled from 'styled-components';

export const ChildBenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChildBenefitRow = styled.div`
  gap: 8px;
  align-items: center;
  display: flex;
`

export const ChildBenefitTitle = styled.p`
  font-weight: 500;
`;

export const ChildBenefitDescription = styled.span`
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 400;
`