import React, { FC } from 'react';
import styled from 'styled-components';
import Person from '../../../interfaces/person.interface';
import UserHistoryItem from './HistoryItem';
import Title from '../../../components/Title';

type Props = {
  users: Person[];
};

const SyledUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const UserSearchHistory:FC<Props> = ({ users }) => {
  return (
    <>
      <Title type="h2">Недавние</Title>
      <SyledUl>
        {users.map((user) => <UserHistoryItem user={user} key={user._id} />)}
      </SyledUl>
    </>
  );
};

export default UserSearchHistory;
