// Core
import { User, WebStorageStateStore } from 'oidc-client';
import { AuthProvider } from 'oidc-react';
import React, { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router';

// Functions
import client from '../functions/api';
import { useAppAuth } from '../functions/use-auth';

// Store
import { tokenSelector } from '../redux/selectors/global.selector';
import { disableLoader, setToken } from '../redux/slices/globalSlice';
import { dropUser, setAvatar, setLoggedIn, setLoggedOut } from '../redux/slices/personSlice';
import { fetchMe } from '../redux/thunks/personThunk';
import { fetchPersonBenefits, fetchPrograms } from '../redux/thunks/fetchPrograms';
import { hasRedirectUrl } from '../redux/selectors/benefits.selector';
import { setRedirectUrl } from '../redux/slices/benefitsSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

const Auth: FC<{ children?:ReactNode, relogin?: boolean }> = ({ children, relogin = false }) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(tokenSelector);
  const auth = useAppAuth();
  const navigate = useNavigate();
  const redirectUrl = useAppSelector(hasRedirectUrl);

  const isDev = process.env.REACT_APP_ENABLE_DEV_FEATURES === 'true';

  const onBeforeSignin = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const programParam = urlParams.get('program');
    if (programParam) {
      dispatch(setRedirectUrl({ redirectUrl: programParam }));
    }
  };

  const signIn = async (usr: User) => {
    if (!usr) return null;
    dispatch(setLoggedIn());
    const { data } = await client.post(
      'api/login',
      { sub: usr.profile.sub },
      {
        headers: {
          Authorization: `Bearer ${usr.id_token}`,
        },
      },
    );

    dispatch(setToken({ token: usr.id_token }));
    if (usr?.profile?.picture) {
      dispatch(setAvatar({ avatar: usr.profile.picture }));
    }
    client.defaults.headers = {
      Authorization: `Bearer ${usr.id_token}`,
    };
    if (data?.sub) {
      if (isDev) {
        return navigate(`/dev/callback?subId=${data.sub}`);
      }
      return navigate('/404');
    }
    dispatch(fetchMe());
    // dispatch(setPerson(data.person));
    dispatch(disableLoader());
    if (redirectUrl) {
      dispatch(fetchPrograms());
      dispatch(fetchPersonBenefits());
      navigate('/loading');
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    (async () => {
      if (auth.isTokenExpired(token)) {
        dispatch(setToken({ token: '' }));
        dispatch(setLoggedOut());
        auth.logOut();
        sessionStorage.clear();
        return navigate('/auth');
      }
      if (relogin) {
        const user = auth.getToken(token);
        await signIn(user);
      }
    })();
  }, []);

  const oidcConfig = {
    onSignIn: signIn,
    userStore: new WebStorageStateStore({
      store: localStorage,
    }),
    onBeforeSignIn: onBeforeSignin,
    scope: 'openid profile',
    authority: process.env.REACT_APP_AUTHORITY,
    clientId: process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    redirectUri: `${window.location.protocol}//${window.location.host}/auth`,
  };

  client.interceptors.response.use(undefined, (err) => {
    if (err.response?.status === 401 || err.response?.status === 403) {
      dispatch(setToken({ token: '' }));
      auth.logOut();
      dispatch(dropUser());
      client.defaults.headers = {
        Authorization: '',
      };
      sessionStorage.clear();
      navigate('/');
    }
    return Promise.reject(err);
  });

  client.interceptors.request.use((config) => {
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  });

  return (
    <AuthProvider {...oidcConfig} onBeforeSignIn={onBeforeSignin}>
      {children}
    </AuthProvider>
  );
};

export default Auth;
