import React from 'react';
import { Spinner } from '@skbkontur/react-ui';
import { LoadPageWrapper } from './LoadingPage.styled';

const LoadingPage = () => {
  return (
    <LoadPageWrapper>
      <Spinner type="big" />
    </LoadPageWrapper>
  );
};

export default LoadingPage;
