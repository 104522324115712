import styled from 'styled-components';

export const InputWRapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  & label {
    width: 100%;
  }

  input {
    display:block;
    width: 100%;
  }
`

export const ChoosePersonWrapper = styled.div`
  margin: 64px 0;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(auto-fill, 280px);
`