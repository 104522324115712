import React, { FC, ReactNode } from 'react';
import { Outlet } from 'react-router';
import { AdminLayoutHeaderWrapper, AdminLayoutWrapper } from './AdminLayout.styled';
import Header from '../../components/Header';
import AdminNav from './AdminNavigation/AdminNav';

type ALProps = {
  children?:ReactNode
};

const AdminPanelLayout: FC<ALProps> = ({ children }) => {
  return (
    <AdminLayoutWrapper>
      <AdminLayoutHeaderWrapper>
        <Header />
        <AdminNav />
      </AdminLayoutHeaderWrapper>
      { children }
      <Outlet />
    </AdminLayoutWrapper>
  );
};

export default AdminPanelLayout;
