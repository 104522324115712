import styled from 'styled-components';

export const TagWrapper = styled.div`
  display: inline-flex;
  gap: 8px;
  align-items: center;
`;

export const TagText = styled.span<{ small: boolean, isFree?: boolean, isSimple?: boolean }>`
  font: var(--${(props) => props.small ? 'small-text' : 'default-text'});
  user-select: none;
  padding: 2px 12px;
  background: var(${(props) => (props.isSimple ? '--lite-gray' : props.isFree ? '--success' : '--warning')});
  border-radius: 24px;
  width: fit-content;
  display: flex;
  white-space: nowrap;
`;

export const PriceTag = styled.span`
  /* margin: 0 0 0 8px; */
`

export const SubAdds = styled.span<{ lines?: number }>`
  color: var(--secondary);
  font-size: 14px;
  max-width: ${(props) => (props.lines > 1 ? '190px' : 'fit-content')};
  white-space: ${(props) => (props.lines > 1 ? 'nowrap' : 'normal')};
`;