import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import insurance_small from '../../../assets/svg/insurance_small.svg';

export const ProgramCard = styled.li`
    background-color: var(--white);
    border-radius: 8px;
    padding: 24px 32px;
    cursor: pointer;
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;

    .disabled {
        position:absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        left:0;
        top:0;
        background: #dadada;
        opacity: 0.5;
        cursor: default
    }
`;

export const ProgramCardSm = styled.li`
    background-color: var(--white);
    width: calc((100% - 96px) / 4 - 32px);
    border-radius: 8px;
    padding: 12px 16px;
    cursor: pointer;
    position: relative;
    min-height: 150px;
    display:flex;
`;

export const CardData = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    z-index:1;
    flex-grow:1;
`;

export const CardDataSm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    z-index:1;
    flex-grow:1;
`;

export const CardHeader = styled.header`

`;

export const CardBody = styled.main`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const CardBodySm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
    justify-content: end;
`;

export const CardDate = styled.div`
    color: #757575;
    font-weight:400;
    font-size: 14px;
    justify-self: left;
`;

const CardBadgeWrapper = styled.div`
    border-radius: 999px;
    padding: 3px 12px;
    font-size: 14px;
    text-transform: lowercase;
    width: fit-content;
`;

type Props = {
  children: ReactNode;
  variant: 'success'|'error'|'neutral'|'warning';
};

export const CardBadge:FC<Props> = ({ variant, children }) => {
  let color:string;

  switch (variant) {
    case 'success': color = '#d7f8ae'; break;
    case 'error': color = '#ffc6c6'; break;
    case 'warning': color = '#fae7b9'; break;
    default: color = '#f0f0f0';
  }

  return (
    <CardBadgeWrapper style={{ backgroundColor: color }}>
      {children}
    </CardBadgeWrapper>
  );
};

export const CardIcon = styled.div`
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;

    img, svg {
        width: 104px;
        height: 104px;
        object-fit: contain;
    }
`;

export const CardIconSm = styled.div`
    position: absolute;
    right: 15px;
    bottom: 2px;
    z-index: 0;

    img, svg {
        width: 70px;
        height: 70px;
        object-fit: contain;
    }
`;
