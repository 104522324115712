import styled from 'styled-components';
import Box from '../../../../../../components/Box/Box';

export const FormRow = styled(Box)`
    > *{
        width: 350px;
    }

    p {
        margin: 0;
    }

    > p:first-child {
        width: 185px;
    }
`;
