import { React, FC, ReactNode } from 'react';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
  font-size:24px;
  width:calc(100% - 16px);
  position: absolute;
  top:1px;
  left:0;
  border-radius:6px;
  padding: 8px;
  box-shadow: 0px 0px 24px 0px #0000001F;
  background-color: #fff;
  z-index:100;
`;

type DMProps = {
  open:boolean;
  children?:ReactNode;
  onBlur?:() => void
};

const DropdownMenu:FC<DMProps> = ({ open, children, onBlur }) => {
  return (
    <div onBlur={() => onBlur()} style={{ position: 'relative' }}>
      {open && (
        <DropdownWrapper>
          {children}
        </DropdownWrapper>
      )}
    </div>
  );
};

export default DropdownMenu;
