// Core
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
// Components
import Auth from './components/Auth';

// Router
import PrivateRoute from './functions/privateRoute';
import RouteWrapper from './functions/routeWrapper';

// Layouts
import DevLayout from './layouts/dev';
import ErrorPageLayout from './layouts/errorPage';
import UserPageLayout from './layouts/main';

// Pages
import ChoosePersonDevPage from './pages/ChoosePersonDev';
import DevLogoutPage from './pages/DevLogout';
import ErrorPage from './pages/ErrorPage';
import MainPage from './pages/Mainpage';
import ProgramPage from './pages/Program';
import LoadingPage from './pages/LoadingPage';
import AdminPanelLayout from './layouts/admin';
import { getIsAdminMode, isLoggedIn } from './redux/selectors/person.selector';
import SearchUserPage from './pages/SearchUser';
import { useAppSelector } from './redux/hooks';
import UserPage from './pages/admin/UserPage/UserPage';

const App = () => {
  const isDev = process.env.REACT_APP_ENABLE_DEV_FEATURES === 'true';
  const isLogged = useAppSelector(isLoggedIn);
  const isAdminMode = useAppSelector(getIsAdminMode);

  const privateRoutes = (
    <Auth>
      <Routes>
        <Route path="/auth" element={<Navigate to="/" />} />
        <Route path="/program/:id" element={<PrivateRoute component={ProgramPage} layout={UserPageLayout} />} />
        <Route path="/loading" element={<PrivateRoute component={LoadingPage} layout={UserPageLayout} />} />
        <Route path="/" element={isAdminMode ? <Navigate to="admin" /> : <PrivateRoute component={MainPage} layout={UserPageLayout} />} />
        <Route path="/program/:id" element={<Navigate to="/auth?program=:id" />} />
        {isAdminMode && (
          <Route path="/admin">
            <Route index element={<Navigate to="panel" />} />
            <Route path="panel" element={<AdminPanelLayout />}>
              <Route index element={<Navigate to="employees" />} />
              <Route path="employees" element={<SearchUserPage />} />
            </Route>
            <Route path="employee" element={<UserPageLayout />}>
              <Route index element={<Navigate to="/admin" />} />
              <Route path=":employeeId" element={<UserPage />} />
            </Route>
          </Route>
        )}
        {isDev && (
          <Route path="/dev/callback" element={<RouteWrapper component={ChoosePersonDevPage} layout={DevLayout} />} />
        )}
        {isDev && (
          <Route path="/dev-logout" element={<RouteWrapper component={DevLogoutPage} layout={DevLayout} />} />
        )}
        <Route path="*" element={<RouteWrapper component={() => <ErrorPage errorCode="404" />} layout={ErrorPageLayout} />} />
      </Routes>
    </Auth>
  );

  const publicRoutes = (
    <Routes>
      <Route path="/program/:id" element={<Navigate to="/auth?program=:id" />} />
      <Route path="/auth" element={<Auth relogin />} />
      {isDev && (
        <Route path="/dev/callback" element={<RouteWrapper component={ChoosePersonDevPage} layout={DevLayout} />} />
      )}
      <Route path="/loading" element={<RouteWrapper component={LoadingPage} layout={DevLayout} />} />
      {isDev && (
        <Route path="/dev-logout" element={<RouteWrapper component={DevLogoutPage} layout={DevLayout} />} />
      )}
      <Route path="/" element={<Navigate to="/auth" />} />
      <Route path="*" element={<RouteWrapper component={() => <ErrorPage errorCode="404" />} layout={ErrorPageLayout} />} />
    </Routes>
  );

  return isLogged ? privateRoutes : publicRoutes;
};

export default App;
