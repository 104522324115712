import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { setRedirectUrl } from '../../redux/slices/benefitsSlice';

type PropTypes = {
  children: ReactNode;
};

const TopPageMiddleware: FC<PropTypes> = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const navigationType = (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).type;
    if (navigationType === 'navigate' && location.pathname.includes('program')) {
      const programId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      dispatch(setRedirectUrl({ redirectUrl: programId }));
    }
  }, []);

  return (
    <>
      {children}
    </>
  );
};

export default TopPageMiddleware;
