import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Person from "../../interfaces/person.interface";
import { connectBenefitToEmployee, disconnectBenefitFromEmployee, fetchAllBenefits, fetchEmployee, fetchEmployeeBenefits, updateBenefitToEmployee } from "../thunks/employeeThunk";
import Benefit from "../../interfaces/benefit.interface";


type EmployeeSlice = {
  employee: Person | null;
  employeeLoading: boolean;
  employeeBenefits: Benefit[];
  employeeBenefitsLoading:boolean;
  connectedEmployeeBenefits: Benefit[];
  currentEmployeeBenefits: Benefit[];
  futureEmployeeBenefits: Benefit[];
  connectableEmployeeBenefits: Benefit[];

  formBenefitsLoading:boolean;
  formBenefits: Benefit[];

  isConnecting: boolean;
  isConnectSuccess: boolean;

  isDisconnecting: boolean;
  isDisconnectSuccess: boolean;

  isUpdating: boolean;
  isUpdateSuccess: boolean;
}

const initialState: EmployeeSlice = {
  employee: null,
  employeeLoading: false,
  employeeBenefits: [],
  employeeBenefitsLoading:false,
  connectedEmployeeBenefits: [],
  currentEmployeeBenefits: [],
  futureEmployeeBenefits: [],
  connectableEmployeeBenefits: [],
  formBenefits: [],
  formBenefitsLoading: false,

  isConnecting: false,
  isConnectSuccess: false,

  isDisconnecting: false,
  isDisconnectSuccess: false,

  isUpdateSuccess: false,
  isUpdating: false
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers:{
    setEmployee(state, action: PayloadAction<Person | null>) {
      state.employee = action.payload;
    },
    setIsConnectSuccess(state, action: PayloadAction<boolean>) {
      state.isConnectSuccess = action.payload
    },
    setIsDisconnectSuccess(state, action: PayloadAction<boolean>) {
      state.isDisconnectSuccess = action.payload
    },
    setIsUpdateSuccess(state, action: PayloadAction<boolean>) {
      state.isUpdateSuccess = action.payload
    },
    resetEmployeeBenefits(state) {
      state.employeeBenefits = [];
      state.currentEmployeeBenefits = [];
      state.connectedEmployeeBenefits = [];
      state.connectableEmployeeBenefits = [];
      state.futureEmployeeBenefits = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployee.fulfilled, (state, { payload }) => {
      state.employee = payload;
      state.employeeLoading = false;
    }),

    builder.addCase(fetchEmployee.pending, (state) => {
      state.employeeLoading = true;
    }),

    builder.addCase(fetchEmployee.rejected, (state) => {
      state.employeeLoading = false;
      state.employee = null;
    }),

    builder.addCase(fetchEmployeeBenefits.fulfilled, (state, { payload }) => {
      state.employeeBenefits = [...payload.benefits, ...payload.connected];
      if(state.employee) {
        const connectedEmployeeBenefits = payload.connected.map((bnft) => bnft.meta?.isForDevelopers ? {...bnft, title: `${bnft.title} IT`} : bnft);
        state.connectableEmployeeBenefits = payload.benefits.map((bnft) => bnft.meta?.isForDevelopers ? {...bnft, title: `${bnft.title} IT`} : bnft);
        state.connectedEmployeeBenefits = connectedEmployeeBenefits;
        state.currentEmployeeBenefits = [...connectedEmployeeBenefits].filter((benefit) => new Date(benefit.bDate) < new Date() && new Date() < new Date(benefit.eDate));
        state.futureEmployeeBenefits = [...connectedEmployeeBenefits].filter((benefit) => new Date(benefit.bDate) > new Date());
      }
      state.employeeBenefitsLoading = false;
    })
    builder.addCase(fetchEmployeeBenefits.pending, (state) => {
      state.employeeBenefitsLoading = true;
    })
    builder.addCase(fetchEmployeeBenefits.rejected, (state) => {
      state.employeeBenefitsLoading = false;
      state.employeeBenefits = [];
      state.currentEmployeeBenefits = [];
      state.connectedEmployeeBenefits = [];
      state.connectableEmployeeBenefits = [];
      state.futureEmployeeBenefits = [];
    })
    builder.addCase(fetchAllBenefits.fulfilled, (state, { payload }) => {
      state.formBenefits = payload.map((bnft) => bnft.meta?.isForDevelopers ? {...bnft, title: `${bnft.title} IT`} : bnft);
      state.formBenefitsLoading = false;
    })
    builder.addCase(fetchAllBenefits.pending, (state) => {
      state.formBenefitsLoading = true;
    })
    builder.addCase(fetchAllBenefits.rejected, (state) => {
      state.formBenefitsLoading = false;
    })

    builder.addCase(connectBenefitToEmployee.fulfilled, (state) => {
      state.isConnecting = false;
      state.isConnectSuccess = true;
    })
    builder.addCase(connectBenefitToEmployee.pending, (state) => {
      state.isConnecting = true;
      state.isConnectSuccess = false;
    })
    builder.addCase(connectBenefitToEmployee.rejected, (state) => {
      state.isConnecting = false;
      state.isConnectSuccess = false;
    })

    builder.addCase(disconnectBenefitFromEmployee.fulfilled, (state) => {
      state.isDisconnecting = false;
      state.isDisconnectSuccess = true;
    })
    builder.addCase(disconnectBenefitFromEmployee.pending, (state) => {
      state.isDisconnecting = true;
      state.isDisconnectSuccess = false;
    })
    builder.addCase(disconnectBenefitFromEmployee.rejected, (state) => {
      state.isDisconnecting = false;
      state.isDisconnectSuccess = false;
    })
    builder.addCase(updateBenefitToEmployee.fulfilled, (state) => {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
    })
    builder.addCase(updateBenefitToEmployee.pending, (state) => {
      state.isUpdating = true;
      state.isUpdateSuccess = false;
    })
    builder.addCase(updateBenefitToEmployee.rejected, (state) => {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    })
  }
})

export const { setEmployee, setIsConnectSuccess, setIsDisconnectSuccess, setIsUpdateSuccess, resetEmployeeBenefits } = employeeSlice.actions;
export default employeeSlice.reducer;