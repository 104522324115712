// Core
import React, { FC } from 'react';
import { cityIn } from 'lvovich';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import { PersonCityWrapper } from './PersonCity.styled';

// Assets
import { ReactComponent as Edit } from '../../assets/svg/edit.svg';

// Store
import { handleCityModal } from '../../redux/slices/globalSlice';
import { personSelector } from '../../redux/selectors/person.selector';
import { fetchCities } from '../../redux/thunks/citiesThunk';

const PersonCity: FC = () => {
  const person = useSelector(personSelector);
  const dispatch = useDispatch();
  const cityHandler = () => {
    dispatch(fetchCities());
    dispatch(handleCityModal());
  };

  if (person?.city?.name.includes('рубеж')) {
    return (
      <PersonCityWrapper
        onClick={() => cityHandler()}
      >
        За рубежом
        <Edit />
      </PersonCityWrapper>
    );
  }
  return (
    <>
      <span>
        в
        {' '}
      </span>
      <PersonCityWrapper
        onClick={() => cityHandler()}
      >
        {person.city && cityIn(person.city.name)}
        <Edit />
      </PersonCityWrapper>
    </>
  );
};

export default PersonCity;
