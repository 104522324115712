import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Person from '../../interfaces/person.interface';
import { dropUserData, fetchAllPersons, fetchMe, fetchPerson, updateMe } from '../thunks/personThunk';

const initialState: {
  person: Person,
  persons: Person[],
  avatar: string,
  loggedIn: boolean,
  appInit: boolean,
  isPersonsLoading: boolean,
  isAdminMode: boolean,
  personsCount:number;
} = {
  person: {} as Person,
  persons: [] as Person[],
  avatar: '',
  loggedIn: false,
  appInit: true,
  isPersonsLoading: false,
  isAdminMode:false,
  personsCount:0
};

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    dropUser(state) {
      state.person = {} as Person;
      state.avatar = '';
    },
    setPerson(state, action: PayloadAction<any>) {
      state.person = action.payload;
    },
    setAvatar(state, action: PayloadAction<{ avatar: string }>) {
      state.avatar = action.payload.avatar;
    },
    setLoggedIn(state) {
      state.loggedIn = true;
    },
    setLoggedOut(state) {
      state.loggedIn = false;
    },
    setIsAdminMode(state, action:PayloadAction<boolean>){
      state.isAdminMode = action.payload;
    },
    clearPersons(state){
      state.persons = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPerson.fulfilled, (state, { payload }) => {
      state.person = payload;
    })
    builder.addCase(fetchAllPersons.fulfilled, (state, { payload }) => {
      state.persons = payload.persons;
      state.personsCount = payload.count;
      state.isPersonsLoading=false;
    })
    builder.addCase(fetchAllPersons.pending, (state) => {
      state.isPersonsLoading=true;
    })
    builder.addCase(fetchAllPersons.rejected, (state) => {
      state.isPersonsLoading=false;
    })
    builder.addCase(updateMe.fulfilled, (state, { payload }) => {
      state.person = {
        ...state.person,
        programsScheme: payload,
      }
    }),
    builder.addCase(dropUserData.fulfilled, (state) => {
      state.person = {} as Person;
      state.avatar = '';
      state.loggedIn = false;
      state.isAdminMode = false;
    })
    builder.addCase(fetchMe.fulfilled, (state, { payload }) => {
      state.person = payload;
      state.appInit = true;
      state.isAdminMode = payload.isAdmin;
    })
  }
});

export const { dropUser, setPerson, setAvatar, setLoggedIn, setLoggedOut, setIsAdminMode, clearPersons } = personSlice.actions;
export default personSlice.reducer;
