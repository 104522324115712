// Core
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

// Store
import { currentStubSelector } from '../../redux/selectors/global.selector';

// Styles
import { Wrapper } from './styles.styled';

type PageProps = {
  defaultStub?: boolean;
  partTimePerson?: boolean;
  notFound?: boolean;
};

const TemporaryPlug: FC<PageProps> = ({ defaultStub, notFound = false, partTimePerson = false }) => {
  const openDates = useSelector(currentStubSelector);

  if (partTimePerson) {
    return (
      <Wrapper>
        Вам не доступен социальный пакет так как вы являетесь внешним совместителем
        <br />
        Если это не так, то напишите об этом нам на 911
        <br />
      </Wrapper>
    );
  }
  if (notFound) {
    return (
      <Wrapper>
        Сервис не может идентифицировать ваше местонахождение, поэтому для
        <br />
        корректного подключения программ страхования, заполните, пожалуйста,
        {' '}
        <a href="https://staff.skbkontur.ru/survey/63c8de5ac51cffc401d2a49a">анкету.</a>
        <br />
        <a href="https://wiki.skbkontur.ru/pages/viewpage.action?pageId=191071755&preview=/191071755/616805270/%D0%A1%D1%82%D0%BE%D0%B8%D0%BC%D0%BE%D1%81%D1%82%D1%8C%20%D0%92%D0%A1%D0%AF%20%D0%BD%D0%B0%202023%20%D0%B3%D0%BE%D0%B4%20%D0%BF%D0%BE%20%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D0%B0%D0%BC%20%D0%B4%D0%BB%D1%8F%20%D0%B0%D0%BD%D0%BA%D0%B5%D1%82%D1%8B%20.xlsx">Тарифы ДМС и стоматологии 2023 при платном подключении</a>
      </Wrapper>
    );
  }

  if (defaultStub) {
    return (
      <Wrapper>
        <b>Обрабатываем заявки на подключение ДМС 2024</b>
        <br />
        <br />
        Мы завершили прием заявок на ДМС 2024.
        <br />
        Программы, на которые вы подали заявки, начнут работать с 29 декабря 2023 года.
        <br />
        В январе 2024 года мы откроем сервис для новых регистраций в течение года — следите за новостями в сообществе
        {' '}
        <a href="https://staff.skbkontur.ru/group/4452">Контур.Здоровье</a>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      Регистрация заявок на страхование завершена.
      <br />
      Следующая регистрация откроется после
      {' '}
      {format(openDates.startDate, 'dd MMMM yyyy', { locale: ru })}
      г.
      <br />
      Ознакомиться с полным перечнем программ страхования на 2024 год вы можете
      {' '}
      <a href="https://wiki.skbkontur.ru/pages/viewpage.action?pageId=730172694">здесь</a>
      .
    </Wrapper>
  );
};

export default TemporaryPlug;
