import { isAfter } from "date-fns";
import Person from "../../../../interfaces/person.interface";

export const getIsUserFired = (user: Person) => {
    const { users } = user;
    let isFired = true;
    for (const account of users) {
        if ((!account.lastWorkingDay && !account.codeStatus) || (account.lastWorkingDay && isAfter(new Date(account.lastWorkingDay), new Date()))) {
            isFired = false;
            break;
        }
    };

    return isFired;
}