import styled from 'styled-components';
import mark from '../../assets/svg/mark.svg';

export const SuccessSubscribeWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  & > :last-child {
    margin: 56px 0 0;
  }
`

export const SuccessIconWrapper = styled.div`
  position: relative;
  height: 180px;
  width: 100px;
  &::after {
    position: absolute;
    content: '';
    width: 107px;
    height: 107px;
    top: 20px;
    right: 28px;
    background: linear-gradient(240deg, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 102%);
    box-shadow: 0px 26px 32px rgba(14, 99, 63, 0.14);
    filter: blur(1px);
    border-radius: 20px;
  }
  &::before {
    position: absolute;
    content: '';
    width: 47px;
    height: 32px;
    top: 60px;
    right: 58px;
    background: url(${mark}) no-repeat center;
    z-index: 1;
  }
`