import { differenceInDays, differenceInMonths, getYear, setYear, startOfMonth, subDays } from 'date-fns';
import Employee from '../../interfaces/employee.interface';
import Person from '../../interfaces/person.interface';
import TabNumber from '../../interfaces/tab-number.interface';
import { RootPersist, RootState } from '../store';

export const findUserJob = (person: Person) => {
  let currentJob: Employee;
  let currentTabNumber: TabNumber;
  person?.tabNumbers?.forEach((tabNumber: TabNumber) => {
    if (tabNumber.codeStatus !== null && !tabNumber.seniorityStart) {

    } else {
      for (const employee of tabNumber.employees) {
        if ((employee.isMain || employee.hasBenefits) && employee.wageRate === 0
          && !employee.eDate && tabNumber.seniorityStop && employee.codeStatus !== 'Удален') {
          currentJob = employee;
          currentTabNumber = tabNumber;
          break;
        }
        if ((person.partTimePerson || employee.isMain || employee.hasBenefits) && !employee.eDate && !tabNumber.seniorityStop && employee.codeStatus !== 'Удален') {
          currentJob = employee;
          currentTabNumber = tabNumber;
          break;
        }
      }
    }
  });
  if (!currentTabNumber) {
    // eslint-disable-next-line prefer-destructuring
    currentTabNumber = person.tabNumbers?.find((tabNumber) => tabNumber.seniorityStart);
    if ( !currentTabNumber ) {
      currentJob = null;
      currentTabNumber = null;
    } else {
    currentJob = currentTabNumber.employees
      .find((empolyee) => (empolyee.isMain || empolyee.hasBenefits) && !empolyee.eDate) || currentTabNumber.employees[0];
    }
  }
  return { currentJob, currentTabNumber };
}

export const personSelector = (state: RootPersist): Person => state.person.person;

export const citySelector = (state: RootPersist) => {
  const { person } = state.person;
  if (person.city) {
    return person.city?.name;
  }


  // if (person.users.length) {
  //   const { currentTabNumber } = findUserJob(person);
  //   if (currentTabNumber) {
  //     const externalCity = currentTabNumber.employees
  //       .find((employee) => employee.isMain && !employee.eDate && employee.city);
  //     if (externalCity?.city) {
  //       return externalCity.city._id ? externalCity?.city._id : externalCity?.city;
  //     }

  //     const currentUser = person.users
  //       .find((user) => user.sid && user.codeStatus === null);
  //     if (currentUser) {
  //       return currentUser?.officeCode?.konturCity?.name || 'Екатеринбург(dev)';
  //     }
  //   }
  //   return person?.users[0]?.officeCode?.konturCity?.name || 'Екатеринбург(dev)';
  // }
  return 'Екатеринбург(dev)';
}

export const userOrgSelector = (state: RootPersist) => {
  if (state?.person?.person?.users?.length) {
    const currentUser = state.person.person.users.find((user) => user.sid && user.codeStatus === null);
    return currentUser?.orgStructure?._id || '';
  }
  return state.person.person.users[0].orgStructure._id as string;
}

export const isLoggedIn = (state: RootPersist) => {
  return !!Object.keys(state.person.person).length ? true : false;
}

export const avatarSelector = (state: RootPersist) => {
  return state.person.avatar;
}

export const getAuthorizedPerson = (state:RootPersist) => {
  return state.person.person
}

export const getIsPersonsLoading = (state:RootState) => {
  return state.person.isPersonsLoading
}

export const isLoggedInByClient = (state: RootPersist) => {
  return state.person.loggedIn;
}

export const getIsAdminMode = (state:RootState) => {
  return state.person.isAdminMode;
}

export const appInitialized = (state: RootPersist) => {
  return state.person.appInit;
}

export const isProbation = (state: RootPersist) => {
  const { person } = state.person;
  if (!person.tabNumbers.length) {
    return 0;
  }

  let { currentJob, currentTabNumber } = findUserJob(person);

  if (currentTabNumber?.seniorityStart) {
    return differenceInMonths(new Date('2023-01-01'), new Date(currentTabNumber.seniorityStart));
  }
  if (currentJob?.bDate) {

    return differenceInMonths(new Date('2023-01-01'), new Date(currentJob.bDate));
  }
  return 0;
}

export const getAllPersons = (state: RootPersist) => {
  return state.person.persons;
}

export const getPersonsCount = (state:RootPersist) => {
  return state.person.personsCount
}

export const getPersonDecreeUntilDate = (
  person: Person,
  ignore = false,
  date: Date,
) => {
  const { currentJob, currentTabNumber } = findUserJob(person);
  if (!currentTabNumber && !ignore) {
    return null;
  }
  if (!currentTabNumber) {
    return {
      decree: false,
      experience: null,
    };
  }
  const { wageRate } = currentJob;
  if (wageRate === 0 && currentTabNumber.seniorityStop) {
    return {
      decree: true,
      experience: Math.abs(
        differenceInMonths(date, new Date(currentTabNumber.seniorityStart)),
      ),
    };
  }
  return {
    decree: false,
    experience: Math.abs(differenceInMonths(date, new Date(currentJob.bDate))),
  };
}
