/* eslint-disable no-underscore-dangle */
import { Tabs } from '@skbkontur/react-ui';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isAfter } from 'date-fns';
import BenefitAccordion from '../../components/BenefitAccordion';
import ProgramHeading from '../../components/ProgramPageHeading';
import Program from '../../interfaces/program.interface';
import { currentBenefitSelectorById, hasRedirectUrl, userBenefitSelector } from '../../redux/selectors/benefits.selector';
import { ProgramPageContent, ProgramPageWrapper, ShowMore, TabContent, StaticContent } from './Program-page.styled';
import { setRedirectUrl } from '../../redux/slices/benefitsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

const ProgramPage = () => {
  // const { params }: { params: { id: string } } = useRouteMatch();

  const params = useParams();
  const dispatch = useAppDispatch();

  const redirectId = useAppSelector(hasRedirectUrl);
  const [active, setActive] = useState('');
  const [singleProgram, setSingleProgram] = useState<Program>({} as Program);
  const navigate = useNavigate();
  const currentBenefit = useAppSelector(currentBenefitSelectorById(params.id));
  if (!currentBenefit) {
    navigate('/404');
  }

  const userBenefits = useAppSelector(userBenefitSelector);
  const [showAll, setShowAll] = useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const ekbDmsId = currentBenefit?.main && currentBenefit._id;
  const isRegistrationEnded = isAfter(new Date(), new Date(currentBenefit?.activationCondition.registrationUntil));

  useEffect(() => {
    if (redirectId && redirectId === params.id) {
      dispatch(setRedirectUrl({ redirectUrl: '' }));
    }
  }, [redirectId]);

  useEffect(() => {
    if (currentBenefit?.type === 'DMS') {
      setActive(currentBenefit?.programs[0].title);
      setSingleProgram(currentBenefit?.programs[0]);
    } else {
      setSingleProgram(currentBenefit?.programs[0]);
    }
    if (currentBenefit?.programs && (currentBenefit?.programs.length > 1)) {
      const benefitData = userBenefits.find((benefit) => benefit.benefitData.benefit === currentBenefit._id);
      const currentProgram = currentBenefit.programs.find((program) => program._id === benefitData?.benefitData?.program);
      if (currentProgram) {
        setActive(currentProgram.title);
        setSingleProgram(currentProgram);
      } else {
        setActive(currentBenefit?.programs[0].title);
        setSingleProgram(currentBenefit?.programs[0]);
      }
    } else if (currentBenefit?.programs && currentBenefit?.programs.length === 1) {
      setSingleProgram(currentBenefit?.programs[0]);
    }
    if (ekbDmsId) {
      if (userBenefits.find((benefit) => benefit.benefitData.benefit === ekbDmsId)) {
        const connectedProgramId = userBenefits.find((benefit) => benefit.benefitData.benefit === ekbDmsId).benefitData.program;
        const connectedProgram = currentBenefit.programs.find((program) => program._id === connectedProgramId);
        setActive(connectedProgram.title);
        setSingleProgram(connectedProgram);
      }
    }
  }, [currentBenefit]);

  useEffect(() => {
    const activeProgram = currentBenefit?.programs.find((program) => program.title === active);
    if (activeProgram) {
      setSingleProgram(activeProgram);
    }
    if (containerRef.current && containerRef.current.scrollHeight > 370) {
      setShouldDisplay(true);
    }
    setShowAll(false);
  }, [active, containerRef]);

  if (currentBenefit) {
    return (
      <ProgramPageWrapper>
        <ProgramHeading benefit={currentBenefit} />
        <ProgramPageContent>
          {currentBenefit.programs && !isRegistrationEnded && (currentBenefit.programs.length > 1) && (
            <Tabs value={active} onValueChange={setActive}>
              {currentBenefit.programs.map((program) => (
                <Tabs.Tab key={program._id} id={program.title}>
                  {program.title}
                </Tabs.Tab>
              ))}
            </Tabs>
          )}
          {(singleProgram && currentBenefit.type === 'DMS') ? (
            <>
              <TabContent
                ref={containerRef}
                contentHeight={containerRef.current?.scrollHeight}
                wide={showAll}
                isFull={!shouldDisplay}
                dangerouslySetInnerHTML={{ __html: singleProgram.content && singleProgram.content.replace(/\\"/g, '"') }}
              />
              {!showAll && shouldDisplay && (
                <ShowMore onClick={() => setShowAll(true)}>
                  Посмотреть полностью
                </ShowMore>
              )}
            </>
          )
            : (
              <StaticContent
                ref={containerRef}
                dangerouslySetInnerHTML={{ __html: singleProgram.content && singleProgram.content.replace(/\\"/g, '"') }}
              />
            )}
          {currentBenefit.children?.length && currentBenefit.children.map((child) => {
            if (child.connected) {
              const currentUserBenefit = userBenefits.find((uBenefit) => uBenefit.benefitData.benefit === child._id);
              if (currentUserBenefit) {
                return child.programs
                  .filter((program) => program._id === currentUserBenefit.benefitData.program)
                  .map((program) => (
                    <BenefitAccordion benefit={child} program={program} key={program._id} />
                  ));
              }
            }
            return (
              <BenefitAccordion benefit={child} key={child._id} program={child.programs[0]} />
            );
          })}
        </ProgramPageContent>
      </ProgramPageWrapper>
    );
  }
  return null;
};

export default ProgramPage;
