/* eslint-disable no-underscore-dangle */

// Core
import React, { FC } from 'react';
import { useNavigate } from 'react-router';

// Interfaces
import Person from '../../interfaces/person.interface';

// Store
import { dropData } from '../../redux/slices/benefitsSlice';
import { dropUser, setLoggedOut } from '../../redux/slices/personSlice';
import { createSubPerson } from '../../redux/thunks/personThunk';
import { useAppDispatch } from '../../redux/hooks';

// Styles
import { PersonWrapper } from './PersonDev.styled';

type PropTypes = {
  person?: Person,
  subId?: string;
};

const PersonDev: FC<PropTypes> = ({ person, subId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnPersonClick = async () => {
    dispatch(await createSubPerson({
      subId,
      person: person._id,
    }));
    dispatch(setLoggedOut());
    dispatch(dropUser());
    dispatch(dropData());
    return navigate('/auth');
  };
  return (
    <PersonWrapper onClick={() => handleOnPersonClick()}>
      <div>
        <span>
          Имя:
          {' '}
          {person.name}
        </span>
      </div>
    </PersonWrapper>
  );
};

export default PersonDev;
