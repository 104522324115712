import styled from 'styled-components';
import programlogo from '../../assets/svg/dms_about.svg';
import optionallogo from '../../assets/svg/insurance_about.svg';
import { TagWrapper } from '../InfoTag/InfoTag.styled';

export const ProgramPageRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  * {
    margin: 0;
  }
`;

export const ProgramPageHeading = styled.section<{ url?: string, type: string, isBonuses?: boolean }>`
  background: var(--gray) url(${(props) => (props.type === 'main' ? programlogo : optionallogo)}) no-repeat bottom 32px right 36px;
  background-image: ${(props) => (props.isBonuses && 'none')};
  padding: 16px 0 48px;
  ${ProgramPageRow} + ${ProgramPageRow} {
    margin: 16px 0 0;
  }
`;

export const ProgramPageTitle = styled.div`
  display: flex;
  align-items: center;
  ${TagWrapper} {
    margin: 0 0 32px 24px;
    align-self: flex-end;
    span {
      white-space: nowrap;
    }
  }
`;

