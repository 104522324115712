import styled from 'styled-components';

export const PageWrapper = styled.section`
`;

export const AvailableList = styled.div`
  margin: 24px 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  grid-gap: 24px;
`;

export const HiddenList = styled.div`
  visibility: hidden;
`;

export const ChangeCityLink = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`