import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { setCurrentBenefit } from '../../redux/slices/benefitsSlice';
import Title from '../Title';
import { InfoProgramWrapper } from './InfoProgram.styled';
import Benefit from '../../interfaces/benefit.interface';
import InfoTagSimple from '../InfoTagSimple';
import { personSelector } from '../../redux/selectors/person.selector';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

type PropTypes = {
  program: Benefit;
};

const InfoProgram: FC<PropTypes> = ({ program }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const person = useAppSelector(personSelector);
  const programDetail = () => {
    dispatch(setCurrentBenefit({ id: program._id }));
    navigate(`/program/${program._id}`);
  };

  return (
    <InfoProgramWrapper onClick={() => programDetail()}>
      <Title type="h4">
        {program.title}
      </Title>
      {program.isFeed && person.compensation ? <InfoTagSimple text={`${person.compensation}`} /> : <></>}
    </InfoProgramWrapper>
  );
};

export default InfoProgram;
