import { Hint } from '@skbkontur/react-ui';
import styled from 'styled-components';

export const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 95%;
  white-space: break-spaces;
  label {
    margin: 8px 0 0; 
  }
  label + label {
    margin: 0;
  }
  & * {
    max-width: 100%;
  }
`
export const RadioLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
`

export const RadioLabelSub = styled.span`
  font: var(--small-text);
  opacity: 0.5;
  margin: 0;
  white-space: nowrap;
`

export const CustomHint = styled(Hint)`
  margin-left: -37px;
`