import React, { FC } from 'react';
import { ErrorPageLayoutWrapper } from './ErrorPageLayout.styled';

const ErrorPageLayout: FC = ({ children }) => {
  return (
    <ErrorPageLayoutWrapper>
      {children}
    </ErrorPageLayoutWrapper>
  );
};

export default ErrorPageLayout;
