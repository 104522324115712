import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TagWrapper } from '../InfoTag/InfoTag.styled';

export const NavTitleRoute = styled.div<{ isBonuses?: boolean }>`
  display: flex;
  align-items: baseline;
  text-decoration: none;
  margin: 0 0 0 -40px;

  a {
    color: var(--black);
    text-decoration: none;
    max-width: 944px;
  }

  h1 {
    margin: ${(props) => (props.isBonuses ? '0' : '32px 0')};
  }
  
  ${TagWrapper} {
    margin: 0 0 0 24px;
  }
`