import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

type DMIProps = {
  children?: ReactNode;
  clickable?:boolean;
  onClick?: () => void;
  onMouseDown?: () => void;
};

const ItemClickableWrapper = styled.div`
    background-color: #fff;
    font-size: 16px;
    border-radius: 6px;
    padding: 12px 16px;
    height: fit-content;
    cursor: pointer;
    display:flex;
    flex-direction: column;
    gap:4px;

    &:hover {
        background-color: #F0F0F0
    }
`;

const ItemWrapper = styled.div`
    background-color: #fff;
    font-size: 16px;
    border-radius: 6px;
    padding: 12px 16px;
    height: fit-content;
    display:flex;
    flex-direction: column;
    gap:4px;
`;

const DropdownMenuItem:FC<DMIProps> = ({ children, clickable = true, onClick }) => {
  if (clickable) {
    return (
      <ItemClickableWrapper onClick={() => onClick()} onMouseDown={(e) => { e.preventDefault(); }}>
        {children}
      </ItemClickableWrapper>
    );
  }
  return (
    <ItemWrapper>
      {children}
    </ItemWrapper>
  );
};

export default DropdownMenuItem;

export const ItemBody:FC<{ children:ReactNode }> = ({ children }) => {
  return (
    <div>
      {children}
    </div>
  );
};

const ItemFooterStyled = styled.div`
    color:#757575;
`;

export const ItemFooter:FC<{ children:ReactNode }> = ({ children }) => {
  return (
    <ItemFooterStyled>
      {children}
    </ItemFooterStyled>
  );
};
