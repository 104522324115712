import { Dropdown, Input } from '@skbkontur/react-ui';
import styled from 'styled-components';
import { TagWrapper } from '../InfoTag/InfoTag.styled';

export const AccordionBenefitTitle = styled.span`
  font-weight: 500;
`;

export const SidePageCity = styled.div`
`;

export const SidePageCityDescription = styled.span`
  font-size: 14px;
  color: var(--secondary-text);
`;

export const SidePageCityLink = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const SideAccordionWrapper = styled.div<{ openChildren?: boolean, isChildren?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 12px 30px 16px;
  border-radius: 8px;
  cursor: ${(props) => ((props.isChildren && !props.openChildren) ? 'pointer' : 'default')};
  :hover {
    ${AccordionBenefitTitle} {
      text-decoration: ${(props) => ((props.isChildren && !props.openChildren) ? 'underline' : 'none')};
      cursor: ${(props) => ((props.isChildren && !props.openChildren) ? 'pointer' : 'default')};
    }
    ${TagWrapper} {
      cursor: ${(props) => ((props.isChildren && !props.openChildren) ? 'pointer' : 'default')};
    }
  }
`;

export const AccordionTitle = styled.div<{ child: boolean, disabled: boolean }>`
  display: inline-block;
  margin: 0;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  span > svg {
    margin-right: 4px;
  }
  & > * {
    cursor: ${(props) => (props.child ? props.disabled ? 'unset' : 'pointer' : 'unset')};
  }
  ${TagWrapper} {
    display: inline-block;
    vertical-align: middle;
  }
`

export const SideAccordionContent = styled.div<{ closed?: boolean, elemHeight?: number, child?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  transition: all 0.4s ease;
  height: ${(props) => (!props.child ? 'fit-content' : props.closed ? '0px' : `${props.elemHeight}px`)};
  overflow-y: hidden;
  & > *:not(:first-child) {
    margin-top: 24px;
  }
`

export const ContentDates = styled.span`
  display: block;
  margin: 8px 0 0;
`

export const DeleteProgramItem = styled.div`
  position: absolute;
  top: 12px;
  right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  span > svg {
    margin-right: 0px;
  }
  &:hover {
    background: #F2F2F2;
  }
`

export const RadioLabelSub = styled.span <{ strikethrough?: boolean }> `
  font: var(--small-text);
  text-decoration: ${(props) => (props.strikethrough ? 'line-through' : '')};
  opacity: 0.5;
  /* margin: 5px 0 10px; */
`

export const ProgramPrice = styled.p`
  margin: 8px 0 0;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--secondary-new);
`

export const SignInput = styled(Input)`
  width: 100%;
  margin: 8px 0 0;
`

export const FlatDropdown = styled(Dropdown)`
  button {  
    border-radius: 1px;
    & span {
      color: var(--black);
    }
  }
`

export const CreditPlan = styled.div`
  width: 100%;
  ${FlatDropdown} {
    margin: 8px 0 0;
  }
`;

export const DisabledDescription = styled.span`
  margin: 14px 0 0;
  font: var(--default-text);
`