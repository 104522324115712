// Core
import React, { useEffect, useState } from 'react';
import { Button, Gapped, Modal, Select } from '@skbkontur/react-ui';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

// Types
import City from '../../interfaces/city.interface';

// Types
import { citiesSelector, isCityLoadingSelector, ruCities } from '../../redux/selectors/global.selector';
import { personSelector } from '../../redux/selectors/person.selector';
import { setUserCityThunk } from '../../redux/thunks/citiesThunk';

// Styles
import { CityDescription, ModalCitySelect, ModalCitySelectItem } from './CityModal.styled';

const RenderCityModal = () => {
  const person = useAppSelector(personSelector);
  const [value, setValue] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [city, setCity] = useState<City>(null);
  const dispatch = useAppDispatch();
  const cityLoaded = useAppSelector(isCityLoadingSelector);
  const cities = useAppSelector(citiesSelector);
  const cityList = useAppSelector(ruCities);

  useEffect(() => {
    if (person.city?.name === 'За рубежом') {
      return setValue(person.city._id);
    }
    if (person.city) {
      setValue('select');
      setCity(person.city);
    }
  }, [person]);

  const modalHandler = (action: 'cancel' | 'save') => {
    setIsLoading(true);
    if (value !== 'select' && value) {
      dispatch(setUserCityThunk({
        city: value || null,
        person,
        action,
      }));
      return null;
    }
    if (value === 'select' && city) {
      dispatch(setUserCityThunk({
        city: city?._id || null,
        person,
        action,
      }));
      return null;
    }
    dispatch(setUserCityThunk({
      city: city?._id || null,
      person,
      action,
    }));
  };
  if (cityLoaded && cityList?.length) {
    return (
      <Modal
        width={486}
        onClose={() => {
          if (!loading) {
            modalHandler('cancel');
          }
        }}
      >
        <Modal.Header>Город страхования в РФ</Modal.Header>
        <Modal.Body>
          <Gapped vertical>
            <ModalCitySelect
              placeholder="Введите или выберете из списка"
              width="100%"
              style={{ borderRadius: '2px' }}
              items={[Select.static(() => <Select.Item><ModalCitySelectItem>Города, в которых доступны программы</ModalCitySelectItem></Select.Item>), ...cityList.map((item) => item.name)]}
              value={city?.name}
              search
              defaultValue={city?.name}
              onValueChange={(val) => {
                const currentCity = cityList.find((valcity) => valcity.name === val);
                if (currentCity) {
                  setCity(currentCity);
                }
              }}
            />
            <CityDescription>
              <p>
                Если вам нужно страхование за рубежом,&nbsp;выберите город страхования в РФ и подключите ДМС. Полис ВЗР будет подключен автоматически.
              </p>
            </CityDescription>
          </Gapped>
        </Modal.Body>
        <Modal.Footer gap={8}>
          <Button
            onClick={() => {
              modalHandler('save');
            }}
            use="primary"
            loading={loading}
            disabled={value === 'select' && !city}
            size="medium"
          >
            Сохранить
          </Button>
          <Button
            onClick={() => {
              if (!loading) {
                modalHandler('cancel');
              }
            }}
            size="medium"
          >
            Отменить
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return <></>;
};

export default RenderCityModal;
