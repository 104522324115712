import { RootPersist } from "../store";

export const getEmployee = (state: RootPersist) => {
  return state.employee.employee;
}

export const getEmployeeBenefits = (state: RootPersist) => {
  return state.employee.employeeBenefits;
}

export const getEmployeeBenefitsLoading = (state: RootPersist) => {
  return state.employee.employeeBenefitsLoading;
}

export const getConnectedEmployeeBenefits = (state: RootPersist) => {
  return state.employee.connectedEmployeeBenefits;
}
export const getCurrentEmployeeBenefits = (state: RootPersist) => {
  return state.employee.currentEmployeeBenefits;
}
export const getFutureEmployeeBenefits = (state: RootPersist) => {
  return state.employee.futureEmployeeBenefits;
}
export const getConnectableEmployeeBenefits = (state: RootPersist) => {
  return state.employee.connectableEmployeeBenefits;
}

export const getFormBenefits = (state: RootPersist) => {
  return state.employee.formBenefits;
}

export const getFormBenefitsLoading = (state: RootPersist) => {
  return state.employee.formBenefitsLoading;
}

export const getIsConnectingBenefit = (state:RootPersist) => {
  return state.employee.isConnecting;
}

export const getIsBenefitConnectSuccessful = (state:RootPersist) => {
  return state.employee.isConnectSuccess;
}

export const getIsBenefitDisconnectSuccessFul = (state:RootPersist) => {
  return state.employee.isDisconnectSuccess;
}

export const getIsDisconnectingBenefit = (state:RootPersist) => {
  return state.employee.isDisconnecting;
}

export const getIsUpdatingEmployeeBenefit = (state:RootPersist) => {
  return state.employee.isUpdating;
}

export const getIsUpdateEmployeeBenefitSuccessful = (state:RootPersist) => {
  return state.employee.isUpdateSuccess;
}