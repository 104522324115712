import { differenceInMonths, isAfter } from 'date-fns';
import Person from '../interfaces/person.interface';
import { findUserJob } from '../redux/selectors/person.selector';

const checkPersonTrial = (person: Person, date = new Date()) => {
  if (!person.tabNumbers.length) {
    return 0;
  }

  let { currentJob, currentTabNumber } = findUserJob(person);

  if (currentJob?.probation) {
    return isAfter(new Date(currentJob.probation), new Date(date));
  }
  return 0;
}

export default checkPersonTrial;