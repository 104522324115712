import styled from 'styled-components';
import { AccordionWrapper } from '../../components/BenefitAccordion/BenefitAccordion.styled';


export const ProgramPageWrapper = styled.section`
`;

export const ProgramPageContent = styled.section`
  background: var(--white);
  ${AccordionWrapper} {
    margin-top: 24px;
  }
  p {
    font: 400 16px/26px LabGrotesqueK, sans-serif;
  }
`

export const StaticContent = styled.div`
  max-width: 800px;
  text-align: justify;
  h1, h2, h3, h4 {
    text-align: start;
  }
  ol {
    list-style-position: inside;
    padding: 0;

    li {
      margin: 16px 0 0;
    }
  }

  a {
    color: var(--links);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  u {
    text-decoration: none;
  }
`

export const TabContent = styled(StaticContent) <{ wide: boolean, contentHeight: number, isFull: boolean }>`
  transition: all .4s ease;
  max-height: ${(props) => (props.wide ? props.contentHeight : '370')}px;
  overflow-y: hidden;
  mask-image: linear-gradient(to bottom, ${(props) => (props.wide || props.isFull ? 'white 100%, transparent 0' : ' black 50%, transparent 100%')});
  @media (min-height: 600px) {
    max-height: ${(props) => (props.wide ? props.contentHeight : '200')}px;
  };
  @media (min-height: 900px) {
    max-height: ${(props) => (props.wide ? props.contentHeight : '300')}px;
  };
  @media (min-height: 1050px) {
    max-height: ${(props) => (props.wide ? props.contentHeight : '400')}px;
  };
  @media (min-height: 1440px) {
    max-height: ${(props) => (props.wide ? props.contentHeight : props.contentHeight)}px;
    mask-image: linear-gradient(to bottom, ${(props) => (props.wide || props.isFull ? 'white 100%, transparent 0' : 'white 100%, transparent 0')});
  };
  tbody tr:first-of-type {
    background: lightgrey;
  }
  tbody td {
    width: 170px;
    text-align: center;
  }
`

export const ShowMore = styled.span`
  display: block;
  margin: 34px 0;
  color: var(--blue);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (min-height: 1440px) {
    display: none;
  }
`