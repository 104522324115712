import React, { FC } from 'react';
import styled from 'styled-components';

const AvatarWrapper = styled.div`
    width: 45px;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    padding: 0.5rem;
    display: grid;
    color: white;
    place-items: center;
    border-radius: 50%;
    background-color: #bfbfbf;
`;

type Props = {
  firstWord: string;
  secondWord: string;
};

const AvatarPlaceHolder:FC<Props> = ({ firstWord, secondWord }) => {
  const firstLetter = firstWord?.[0] ?? '';
  const secondletter = secondWord?.[0] ?? '';
  return (
    <AvatarWrapper>
      {`${firstLetter}${secondletter}`}
    </AvatarWrapper>
  );
};

export default AvatarPlaceHolder;
