import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../functions/api';
import Cities from '../../interfaces/fetch-cities.intarface';
import Person from '../../interfaces/person.interface';
import { handleCityModal } from '../slices/globalSlice';
import { fetchPersonBenefits, fetchPrograms } from './fetchPrograms';
import { fetchMe } from './personThunk';


export const fetchCities = createAsyncThunk<Cities>(
  'cities/all',
  async (dispatch, state) => {
    const { data }: { data: Cities } = await client.get('/api/city/cities');
    return data;
  }
)

export const setUserCityThunk = createAsyncThunk(
  'user/setCity',
  async (payload: { city: string, person: Person, action: 'cancel' | 'save' }, state) => {
    if ((payload.city && payload.city !== payload.person?.city?._id && payload.action === 'save') || !payload.person.personSetCity) {
      await client.post('api/person/set-user-city', {
        city: payload.city,
      });
      await state.dispatch(fetchMe());
      await state.dispatch(fetchPersonBenefits());
      await state.dispatch(fetchPrograms());
    }

    state.dispatch(handleCityModal())
  }
)