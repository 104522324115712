import React, { FC, useEffect, useState } from 'react';
import { Button, Radio, RadioGroup, SidePage } from '@skbkontur/react-ui';
import { useAppSelector } from '../../../../../redux/hooks';
import { allAvailableBenefits, getSelectedBenefit } from '../../../../../redux/selectors/benefits.selector';
import ConnectBenefitForm from './forms/ConnectBenefit';
import EditBenefitForm from './forms/EditBenefit';

type Props = {
  onClose: () => void;
  type: 'edit'|'connect'
};

const BenefitSidepage:FC<Props> = ({ onClose, type }) => {
  const benefit = useAppSelector(getSelectedBenefit);

  return (
    <SidePage width={640} onClose={onClose} ignoreBackgroundClick>
      {type === 'connect' && <ConnectBenefitForm benefit={benefit} onSidepageClose={onClose} />}
      {type === 'edit' && <EditBenefitForm benefit={benefit} onSidepageClose={onClose} />}
    </SidePage>
  );
};

export default BenefitSidepage;
