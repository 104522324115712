import React, { FC } from 'react';
import Benefit from '../../../interfaces/benefit.interface';
import Box from '../../Box/Box';

type Props = {
  benefitTitle: string;
  date: string;
};

const BenefitOption:FC<Props> = ({ benefitTitle, date }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <div>{benefitTitle}</div>
      <Box sx={{ color: '#949494' }}>
        {date}
      </Box>
    </Box>
  );
};

export default BenefitOption;
