import styled from 'styled-components';
import happyface from './assets/facehappy.svg';

export const ProgramStubWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-end;
  width: 292px;
  height: 292px;
  margin: 0 auto;
  border-radius: 184px;
  background: #fff url(${happyface}) no-repeat center 43px;
`;

export const ProgramStubParagraph = styled.p`
  position: absolute;
  top: 188px;
  max-width: 241px;
  text-align: center;
`;

export const ProgramStubParagraphLong = styled.p`
  position: absolute;
  top: 188px;
  max-width: 241px;
  text-align: center;
  font: var(--small-text)
`;
