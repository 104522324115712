/* eslint-disable react/jsx-one-expression-per-line */
import { Button } from '@skbkontur/react-ui';
import React from 'react';
import { useNavigate } from 'react-router';
import format from 'date-fns/format';
import { ru } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessSubscribeWrapper, SuccessIconWrapper } from './SuccessSubscribe.styled';
import { ReactComponent as SuccessSVG } from '../../assets/svg/green_square.svg';
import Title from '../Title';
import { hideForm } from '../../redux/slices/benefitsSlice';
import { currentBenefitSelector } from '../../redux/selectors/benefits.selector';
import { useChannelContext } from '../../functions/channelContext';

const SuccessSubscribe = ({ onButtonBlocked: setIsAddButtonBlocked }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentBenefit = useSelector(currentBenefitSelector);
  const channel = useChannelContext();

  const confirmAndRedirect = () => {
    dispatch(hideForm());
    channel.sendMessage('program_connected');
    navigate('/');
  };

  const handleAccept = () => {
    setIsAddButtonBlocked(false);
    confirmAndRedirect();
  };

  return (
    <SuccessSubscribeWrapper>
      <SuccessIconWrapper>
        <SuccessSVG />
      </SuccessIconWrapper>
      <Title type="h3">
        Программа подключена
      </Title>
      <p>
        Начнёт действовать {Object.keys(currentBenefit).length ? format(new Date(currentBenefit?.bDate), 'dd MMMM', { locale: ru }) : ' в указанный срок'},
        <br />
        перед этим на почту придёт страховой полис
      </p>
      <Button onClick={() => handleAccept()} use="primary" size="medium">
        Хорошо
      </Button>
    </SuccessSubscribeWrapper>
  );
};

export default SuccessSubscribe;
