import React, { FC } from 'react';
import styled from 'styled-components';

const AdminPageWrapper = styled.div`
    background-color:#fff;
    padding: 20px 124px 60px;
    flex-grow: 1;
    display:flex;
    flex-direction:column;
    gap:20px;
`;

export default AdminPageWrapper;
