import React, { FC } from 'react';
import { ComboBox, ComboBoxExtendedItem } from '@skbkontur/react-ui';
import Benefit from '../../../../../../interfaces/benefit.interface';
import BenefitOption from '../../../../../../components/Select/BenefitOption/BenefitOption';
import Box from '../../../../../../components/Box/Box';
import City from '../../../../../../interfaces/city.interface';

export type CityOption = {
  value: City,
  label: string,
};

type Props = {
  cities: City[],
  value: CityOption,
  onChange: (value: CityOption) => void,
  disabled?: boolean,
  onInputValueChange?: (value:string) => string | void,
  onUnexpectedInput? : (value:string) => CityOption | void
};

const CitiesSelect:FC<Props> = ({ cities, value, onChange, disabled = false, onInputValueChange, onUnexpectedInput }) => {
  const options = cities.map((city) => ({
    value: city,
    label: city.name,
  }));

  const getOptions = (q:string) => {
    return Promise.resolve(
      options.filter((x) => x.label.toLowerCase().includes(q.toLowerCase())),
    ) as unknown as Promise<ComboBoxExtendedItem<CityOption>[]>;
  };

  return (
    <Box>
      <ComboBox onUnexpectedInput={onUnexpectedInput} disabled={disabled} width="60%" value={value} onValueChange={(city) => { onChange(city); }} getItems={getOptions} />
    </Box>
  );
};

export default CitiesSelect;
