import styled from 'styled-components';

export const TagWrapper = styled.div`

`;

export const TagText = styled.span<{ small: boolean, isFree?: boolean, isSimple?: boolean }>`
  font: var(--${(props) => props.small ? 'small-text' : 'default-text'});
  user-select: none;
  padding: 2px 12px;
  background: var(${(props) => (props.isSimple ? '--lite-gray' : props.isFree ? '--success' : '--warning')});
  border-radius: 24px;
  width: fit-content;
  display: flex;
  white-space: nowrap;
`;

export const PriceTag = styled.span`
  /* margin: 0 0 0 8px; */
`