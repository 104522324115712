import React, { FC } from 'react';
import { Radio, RadioGroup } from '@skbkontur/react-ui';
import Box from '../../../../../../components/Box/Box';
import Program from '../../../../../../interfaces/program.interface';

type Props = {
  programs: Program[],
  value: Program,
  onChange: (value: Program) => void
};

const ProgramTypeRadioGroup:FC<Props> = ({ programs, value, onChange }) => {
  return (
    <Box>
      <RadioGroup onValueChange={onChange} width="100%" defaultValue={programs[0]} value={value}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {programs.map((program) => <Radio key={program._id} value={program}>{program.title}</Radio>)}
        </Box>
      </RadioGroup>
    </Box>
  );
};

export default ProgramTypeRadioGroup;
