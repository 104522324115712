import { MenuItem, TooltipMenu, Group } from '@skbkontur/react-ui';
import styled from 'styled-components';
import Menu from './assets/menu.svg';
import MenuActive from './assets/menu_active.svg';

export const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 64px;
  padding: 16px 0 0;
  background-color: var(--gray);
  position: relative;
  z-index: 1;
`;

export const HeaderTitle = styled.a`
  margin: 0;
  font: 500 20px/24px LabGrotesqueK, sans-serif;
  color: var(--black);
  cursor: pointer;
  text-decoration: none;
  &:hover, &:visited, &:active {
    color: inherit;
  }
`;

export const KonturMenuItem = styled(MenuItem)`
  display: flex;
  align-items: baseline;
  font: var(--default-text);
  border-radius: 24px;
  transition: all .4s ease;

  a {
    color: var(--black);
    text-decoration: none;
  }
  &:hover, &:active {
    background: #0000000F;
    a { 
      text-decoration: none;
    }
  }
`;

export const MenuWrapper = styled.div<{ active: boolean }>`
  position: absolute;
  background: #f6f6f6;
  top: ${(props) => props.active ? '72px' : '-720px'};
  padding: 48px 0;
  transition: all .5s ease;
  width: ${(props) => props.active ? '140%' : '100%' };
  left: ${(props) => props.active ? '-20%' : '0' };
  z-index: -1;
  height: ${(props) => props.active ? 'auto' : '0'};
  overflow: ${(props) => props.active ? 'visible' : 'hidden'};
  @media (max-width: 1023px) {
    display: ${(props) => props.active ? 'block' : 'none'};
    left: ${(props) => props.active ? '0' : '1024px'};
    top: 72px;
    width: 100%;
  };
`;

export const KonturMenuStub = styled.div<{ active: boolean }>`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  transition: all .4s ease;
  background: url(${(Menu)}) no-repeat center center;
  margin-top: -2px;
  background-color: ${(props) => props.active ? ' #0000000F' : ''};
  &:hover, &:active {
    background-color: #0000000F;
  }
`;

export const TooltipMenus = styled(TooltipMenu)`
  border: 40px;
`;

export const HeaderIcon = styled.svg<{ type?: string, height?: string }>`
  background: url(${(props) => (props.type)}) center no-repeat;
  width: 15px;
  height: ${(props) => (props.height ? props.height : '20')}px;
`;

export const HeaderControl = styled(Group)`
  align-items: center;
  gap: 8px;
  & > :first-child button {
    padding-right: 8px;
  }

  & > :nth-child(2) > div > button {
    cursor: pointer;
  }

  & > :last-child > div > button {
    padding-right: 0;
  }

  & > :last-child > div > button > div {
    margin-right: -18px;
  }
`;