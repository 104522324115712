import { ArrowChevronDown } from '@skbkontur/react-icons';
import styled from 'styled-components';
import blank from './assets/blank.svg';


export const AvatarImg = styled.div<{ url?: string }>`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: url(${(props) => (props.url ? props.url : blank)});
  background-size: contain;
  box-sizing: border-box;
`;

export const AvatarMore = styled(ArrowChevronDown)`
  margin: 0 0 0 4px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
`;

export const Popper = styled.div`
  display: flex;
  position: absolute;
  top: calc(100% + 4px);
  left: -100%;
  flex-direction: column;
  width: fit-content;
  padding: 9px 8px 11px 8px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 4px 12px 0px #00000029;
  box-sizing: border-box;
  white-space: nowrap;
`