import styled from 'styled-components';

export const InfoProgramsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  grid-gap: 24px;
`;

export const InfoPageWrapper = styled.section`
  margin: 72px 0;
  padding-bottom: 64px;
`;
