import { ArrowChevronLeft } from '@skbkontur/react-icons';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Title from '../Title';
import { NavTitleRoute } from './NavTitle.styled';

type PropTypes = {
  path: string;
  isBonuses?: boolean;
};

const NavTitle: FC<PropTypes> = ({ children, path, isBonuses }) => {
  return (
    <NavTitleRoute isBonuses={isBonuses}>
      <Link to={path}>
        <ArrowChevronLeft size={40} />
      </Link>
      <Link to={path}>
        <Title>
          {children}
        </Title>
      </Link>
    </NavTitleRoute>
  );
};

export default NavTitle;
