import styled from 'styled-components';

export const AdminLayoutWrapper = styled.div`
  height: 100%;
  display:flex;
  flex-direction:column;
`;

export const AdminLayoutHeaderWrapper = styled.div`
  padding: 0 124px 24px;
  display:flex;
  flex-direction:column;
  gap: 24px;
`;

export const LayoutHeading = styled.h2`
  font: 400 32px/40px LabGrotesqueK, sans-serif;
  margin: 0;
`;

export const LayoutParagraph = styled.p`
  font: 400 16px/20px LabGrotesqueK, sans-serif;
  color: rgba(0, 0, 0, .6);
  margin: 0;
`;
