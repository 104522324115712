// Core
import { useAuth } from 'oidc-react';
import React, { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// Components
import { Button, MenuItem } from '@skbkontur/react-ui';

// Functions
import useOnClickOutside from '../../functions/useOnClickOutside';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

// Store
import { avatarSelector, getIsAdminMode, personSelector } from '../../redux/selectors/person.selector';
import { dropData } from '../../redux/slices/benefitsSlice';
import { setToken } from '../../redux/slices/globalSlice';
import { dropUser, setIsAdminMode } from '../../redux/slices/personSlice';
import { deletePerson, dropUserData } from '../../redux/thunks/personThunk';

// Styles
import { AvatarImg, AvatarMore, AvatarWrapper, Popper } from './Avatar.styled';

const Avatar: FC = () => {
  const ref = useRef(null);
  const parent = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const avatar = useAppSelector(avatarSelector);
  const person = useAppSelector(personSelector);
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    dispatch(setToken({ token: '' }));
    dispatch(await dropUserData());
    dispatch(dropData());
    dispatch(dropUser());
    await auth.signOut();
    navigate('/');
  };

  const handleDeletePerson = async () => {
    dispatch(await deletePerson());
    return handleLogout();
  };

  const handleOpen = () => {
    if (isOpen) {
      return setIsOpen(false);
    }
    return setIsOpen(true);
  };

  const handleClickOutside = (e) => {
    if (parent.current && parent.current.contains(e.target)) {
      return;
    }

    if (!isOpen) {
      return;
    }
    setIsOpen(false);
  };

  const isAdmin = Object.prototype.hasOwnProperty.call(person, 'isAdmin') && person.isAdmin;

  const isAdminMode = useAppSelector(getIsAdminMode);

  const isDev = process.env.REACT_APP_ENABLE_DEV_FEATURES === 'true';

  useOnClickOutside(ref, handleClickOutside);

  return (
    <AvatarWrapper ref={parent} onClick={() => handleOpen()}>
      <AvatarImg url={avatar} />
      <AvatarMore />
      {isOpen && (
        <Popper ref={ref}>
          <MenuItem onClick={() => handleLogout()}>
            Выйти
          </MenuItem>
          {isDev && (
            <MenuItem onClick={() => handleDeletePerson()}>
              Выйти и удалить юзера
            </MenuItem>
          )}
          {isDev && (
            <MenuItem>
              {person.developerPerson ? 'Разработчик' : 'Не разработчик'}
            </MenuItem>
          )}

          {isAdmin && (
            <>
              <MenuItem>
                <a
                  href={`${process.env.REACT_APP_PUBLIC_URL}/api/report/generate`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Отчет для бухгалтерии
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href={`${process.env.REACT_APP_PUBLIC_URL}/api/report/counterpart`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Отчет для контрагентов
                </a>
              </MenuItem>
              {isAdminMode
                ? (
                  <MenuItem
                    onClick={() => {
                      dispatch(setIsAdminMode(false));
                      navigate('/');
                    }}
                  >
                    Выйти из панели
                    <br />
                    администрирования
                  </MenuItem>
                )
                : <MenuItem onClick={() => dispatch(setIsAdminMode(true))}>Панель администрирования</MenuItem>}
            </>
          )}
        </Popper>
      )}
    </AvatarWrapper>
  );
};

export default Avatar;
