import styled from 'styled-components';
import { ProgramPageCanEdit, ProgramPageHeadingP } from '../HeadingStatus/HeadingStatus.styled';
import { TagWrapper } from '../InfoTag/InfoTag.styled';
import Title from '../Title';

export const AccordionWrapper = styled.div`
  border-radius: 16px;
  background: var(--gray);
  margin: 24px -60px 0;
  padding: 24px 60px;
  width: 100%;
  ${ProgramPageCanEdit} {
    display: flex;
    align-items: flex-end; 
    div {
      flex-direction: row-reverse;
      ${ProgramPageHeadingP} {
        margin: 0 16px 0 0;
      }
    }
  }
`

export const AccordionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`

export const AccordionHeadingHeader = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 0 0 -36px;
  cursor: pointer;
  h2 {
    margin: 0 0 0 4px; 
  }
  & > span {
    transition: all .4s ease;
    transform: rotate(${(props) => (props.active ? '180deg' : '0')});
  }

  ${TagWrapper} {
    margin: 0 0 0 16px;
  }
`

export const AccordionHeadingContent = styled.div`

`
export const AccordionContent = styled.div<{ active: boolean, contentHeight }>`
  transition: all .4s ease;
  height: ${(props) => (props.active ? props.contentHeight : '0')}px;
  max-height: fit-content;
  max-width: 66%;
  overflow-y: hidden;
  font: 400 16px/22px LabGrotesqueK, sans-serif;
  a {
    color: var(--links);
    text-decoration: none;
  }
  li {
    font: 400 16px/22px LabGrotesqueK, sans-serif;
  }
`